import { motion } from "framer-motion";
import "./svgStyle.css";

const YourSVGComponent = ({ classNam }) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 110 90"
      className={classNam}
    >
      <polygon
        class="cls-6"
        points="5.94 5.45 39.16 22.83 70.33 5.45 104.06 22.83 104.06 84.66 69.82 66.77 37.63 84.66 5.94 66.26 5.94 5.45"
      />
      <motion.text
        class="cls-1"
        transform="translate(21.99 43.23)"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 2,
          repeat: Infinity,
          repeatDelay: 2,
        }}
      >
        <tspan class="cls-4">
          <tspan class="cls-2" x="0" y="0">
            EXP
          </tspan>
          <tspan class="cls-10" x="28.38" y="0">
            L
          </tspan>
          <tspan class="cls-2" x="35.92" y="0">
            OR
          </tspan>
          <tspan x="57.69" y="0">
            E
          </tspan>
        </tspan>
        <tspan class="cls-3" x="-3.26" y="14.99">
          TH
        </tspan>
        <tspan class="cls-4" x="16.45" y="14.99">
          E
        </tspan>
        <tspan class="cls-5" x="24.77" y="14.99">
          {" "}
        </tspan>
        <tspan class="cls-7" x="29.46" y="14.99">
          M
        </tspan>
        <tspan class="cls-8" x="45.09" y="14.99">
          A
        </tspan>
        <tspan class="cls-9" x="58.33" y="14.99">
          P
        </tspan>
      </motion.text>
    </svg>
  );
};

export default YourSVGComponent;
