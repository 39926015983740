import React from "react";
import axios from "axios";

export const censusRequestAge = () => {
  let response = axios
    .post(`${process.env.REACT_APP_API_BASE_URL}/api/index.php`, {
      executeQuery: "censusAge",
      apiKey: process.env.REACT_APP_API_KEY,
    })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
  return response;
};

export const formatAgeData = (data) => {
  const yearsArray = data.map((element) => {
    const { year } = element;
    return { year };
  });

  const regionsArray = data.map((element) => element.name);
  const regionsArrayUnique = Array.from(new Set(regionsArray));

  const yearsArrayUnique = yearsArray
    .filter(isUnique)
    .map((obj) => obj.year)
    .sort((a, b) => parseInt(a) - parseInt(b));

  const agesArray = ["0-14", "15-24", "25-54", "55+", "Not stated"];

  const formatedObject = {
    years: yearsArrayUnique,
    ages: agesArray,
    regions: regionsArrayUnique,
  };

  return formatedObject;
};

function isUnique(obj, index, self) {
  return index === self.findIndex((o) => o.year === obj.year);
}

export const censusRequestNationalityYears = () => {
  let response = axios
    .post(`${process.env.REACT_APP_API_BASE_URL}/api/index.php`, {
      executeQuery: "censusNationalityYears",
      apiKey: process.env.REACT_APP_API_KEY,
    })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
  return response;
};

export const censusRequestNationalityRegions = (year) => {
  let response = axios
    .post(`${process.env.REACT_APP_API_BASE_URL}/api/index.php`, {
      executeQuery: "censusNationalityRegions",
      apiKey: process.env.REACT_APP_API_KEY,
      dataObject: { year: year },
    })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
  return response;
};

export const censusRequestNationalityNationalities = (year, regionID) => {
  let response = axios
    .post(`${process.env.REACT_APP_API_BASE_URL}/api/index.php`, {
      executeQuery: "censusNationalityNationalities",
      apiKey: process.env.REACT_APP_API_KEY,
      dataObject: { year: year, regionID: regionID },
    })
    .then(function (response) {
      let preFormat = response.data.data;

      const keysToRemove = ["Female", "Male"];
      const formated = Object.keys(preFormat[0]).filter(
        (key) => !keysToRemove.includes(key)
      );

      const formatedObject = {
        dataMap: response.data.data,
        nationalities: formated,
      };
      return formatedObject;
    })
    .catch(function (error) {
      console.log(error);
    });
  return response;
};

export const getColor = (d) => {
  return d > 3000
    ? "#310A03"
    : d > 2000
    ? "#3F191A"
    : d > 1000
    ? "#571F20"
    : d > 800
    ? "#6F2525"
    : d > 600
    ? "#872A2A"
    : d > 400
    ? "#9F2F2F"
    : d > 200
    ? "#B73434"
    : d > 100
    ? "#CF3A3A"
    : d > 50
    ? "#EE3434"
    : "#FF4545";
};

export const getPositions = (textPosition) => {
  var result = textPosition.split(" ");
  result = result.map((element) => {
    let x = element.split(",");
    let ret = [x[1], x[0]];
    return ret;
  });
  return result;
};

export const filterAgeData = (data) => {
  const allData = data.data;
  const ages = data.ages;
  const year = data.year;
  const regions = data.regions;

  var threwRegionsNotMatch = allData;

  if (!regions.includes("All")) {
    threwRegionsNotMatch = allData.filter((element) =>
      regions.includes(element.name)
    );
  }

  const threwYearsNotMatch = threwRegionsNotMatch.filter(
    (element) => element.year == year
  );

  const threwUnnessesaryData = threwYearsNotMatch.map((element) => ({
    name: element.name,
    outline: element.jsonOutline,
    agesNumber: element[ages],
    female: element.female,
    male: element.male,
    ages: ages,
    number: parseInt(element.male) + parseInt(element.female),
  }));

  return threwUnnessesaryData;
};

export const presentCensusPaneNationalityData = (data, nationality) => {
  const malefemaletDataArray = [];
  const nationalityDataArray = [];

  malefemaletDataArray.push(["Distribution Male/Female", "Values"]);
  nationalityDataArray.push(["Distribution Nationality", "Values"]);

  for (const key of Object.keys(data[0])) {
    if (key == "Male" || key == "Female") {
      const value = parseInt(data[0][key]);
      malefemaletDataArray.push([key, value]);
    } else {
      const value = parseInt(data[0][key]);
      nationalityDataArray.push([key, value]);
    }
  }

  let dataToPresent = {
    malefemale: malefemaletDataArray,
    nationality: nationalityDataArray,
  };

  return dataToPresent;
};
