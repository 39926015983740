import React from "react";
import { Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import axios from "axios";

function PointsMarker({
  pointOfInterest,
  index,
  setDataForTheLeftPane,
  setOpen,
}) {
  const pointsOfInterestIcon = new L.divIcon({
    className: "custom-icon",
    html: `<img src="/point_icon.svg"/>`,
    iconSize: [24, 24],
  });

  const map = useMap();

  return (
    <Marker
      key={index}
      position={[pointOfInterest.latitude, pointOfInterest.longitude]}
      icon={pointsOfInterestIcon}
      eventHandlers={{
        click: () => {
          axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/api/index.php`, {
              executeQuery: "materialPointOfInterest",
              apiKey: "dEePNIc2023",
              dataObject: {
                year: 2020,
                pointOfInterestID: pointOfInterest.id,
              },
            })
            .then(function (response) {
              let data = {
                material: response.data.data,
                info: pointOfInterest,
                type: "POINT OF INTEREST",
              };
              setDataForTheLeftPane(data);
              setOpen(true);
              map.setView(
                [
                  parseFloat(pointOfInterest.latitude),
                  parseFloat(pointOfInterest.longitude) + 0.01,
                ],
                15
              );
            })
            .catch(function (error) {
              console.log(error);
            });
        },
        mouseout: (event) => event.target.closePopup(),
        mouseover: (event) => event.target.openPopup(),
      }}
    >
      <Popup>
        <img
          className="popup-image"
          src={`${process.env.REACT_APP_API_BASE_URL}/material/${pointOfInterest.frontImage}`}
        />
        <div className="popup-custom-title">
          {pointOfInterest.name.substring(0, 39)}
        </div>
      </Popup>
    </Marker>
  );
}

export default PointsMarker;
