import React, { useState, useEffect } from "react";

import { Chart } from "react-google-charts";

import Spinner from "react-bootstrap/Spinner";

import { employmentDataPresenation } from "../../services/BusinessActivityContext/business.context";

import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";

function BusinessActivityEmployment({ data, setLoading, loading }) {
  const [correctData, setCorrectData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setCorrectData(
      employmentDataPresenation(data.data, data.gender, data.sector)
    );
    setLoading(false);
  }, [data]);

  const handleSelect = ({ chartWrapper }) => {
    const chart = chartWrapper.getChart();
    const selection = chart.getSelection();

    if (selection.length > 0) {
      const selectedRowIndex = selection[0].row;

      if (selectedRowIndex >= 0 && selectedRowIndex < data.length) {
        const selectedData = data[selectedRowIndex];
      }
    }
  };

  return (
    <>
      <div className="position-buttons-on-the-map-business">
        <Button
          onClick={() => {
            navigate("/census");
          }}
          className="population-census-button-map"
        >
          Demography
        </Button>
        <Button
          onClick={() => {
            navigate("/monuments");
          }}
          className="monuments-button-map"
        >
          Monument Building
        </Button>
      </div>
      {loading && <Spinner animation="border" style={{ color: "#00FF97" }} />}
      {!loading && (
        <Chart
          chartType="ColumnChart"
          width="100%"
          height="600px"
          data={correctData}
          options={{
            legend: "none",
            backgroundColor: "transparent",
            vAxis: {
              ticks: { count: 5 },
              textStyle: {
                color: "white",
              },
            },
            hAxis: {
              textPosition: "none",
              // textStyle: {
              //   color: "white",
              // },
            },
          }}
          legendToggle
          chartEvents={[
            {
              eventName: "select",
              callback: handleSelect,
            },
          ]}
        />
      )}
    </>
  );
}

export default BusinessActivityEmployment;
