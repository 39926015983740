import React, { useState, useEffect } from "react";

import { Chart } from "react-google-charts";

import Spinner from "react-bootstrap/Spinner";

import { presentCensusPaneNationalityData } from "../../services/CensusActivityContext/census.context";

import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";

function CensusRightPaneNationality({ data, setLoading, loading }) {
  const [correctData, setCorrectData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setCorrectData(
      presentCensusPaneNationalityData(data.data, data.nationality)
    );
    setLoading(false);
  }, [data]);

  const handleSelect = ({ chartWrapper }) => {
    const chart = chartWrapper.getChart();
    const selection = chart.getSelection();

    if (selection.length > 0) {
      const selectedRowIndex = selection[0].row;

      if (selectedRowIndex >= 1 && selectedRowIndex < data.length) {
        const dataPoint = data[selectedRowIndex];
        const content = getCustomTooltip(dataPoint);
      }
    }
  };

  const getCustomTooltip = (dataPoint) => {
    const percentage = dataPoint[1].toString();

    return `
      <div>
        <strong>${dataPoint[0]}</strong>: ${percentage}%
      </div>
    `;
  };

  return (
    <>
      <div className="position-buttons-on-the-map-business">
        <Button
          onClick={() => {
            navigate("/business");
          }}
          className="business-activity-button-map"
        >
          Business Activity
        </Button>
        <Button
          onClick={() => {
            navigate("/monuments");
          }}
          className="monuments-button-map"
        >
          Monument Building
        </Button>
      </div>
      {loading && <Spinner animation="border" style={{ color: "#D2042D" }} />}
      {!loading && (
        <div className="pie-charts-section">
          {correctData.malefemale.length != 1 && (
            <Chart
              chartType="PieChart"
              width="100%"
              height="100%"
              data={correctData.malefemale}
              options={{
                backgroundColor: "transparent",
                sliceVisibilityThreshold: 0,
                chartArea: { width: "90%", height: "90%" },
                is3D: false,
                colors: ["#EE3434", "#9F2F2F"],
                pieSliceBorderColor: "transparent",
                slices: {
                  1: { offset: 0.1 },
                  2: { offset: 0.1 },
                },
                legend: {
                  textStyle: {
                    color: "white",
                    fontSize: 14,
                  },
                },
              }}
              legendToggle
              chartEvents={[
                {
                  eventName: "select",
                  callback: handleSelect,
                },
              ]}
            />
          )}
          {correctData.nationality.length != 1 && (
            <>
              <br></br>
              <br></br>
              <Chart
                chartType="PieChart"
                width="100%"
                height="100%"
                data={correctData.nationality}
                options={{
                  // legend: "none",
                  sliceVisibilityThreshold: 0,
                  backgroundColor: "transparent",
                  is3D: false,
                  chartArea: { width: "90%", height: "90%" },
                  colors: [
                    "#FF4545",
                    "#EE3434",
                    "#CF3A3A",
                    "#571F20",
                    "#3F191A",
                    "#310A03",
                    "#B73434",
                  ],
                  slices: {
                    1: { offset: 0.2 },
                    2: { offset: 0.2 },
                    3: { offset: 0.2 },
                    4: { offset: 0.2 },
                    5: { offset: 0.2 },
                    6: { offset: 0.2 },
                  },
                  pieSliceBorderColor: "transparent",
                  legend: {
                    textStyle: {
                      color: "white",
                      fontSize: 14,
                    },
                  },
                }}
                legendToggle
                chartEvents={[
                  {
                    eventName: "select",
                    callback: handleSelect,
                  },
                ]}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}

export default CensusRightPaneNationality;
