import React, { useState } from "react";
import DynamicSplitter from "../components/Core/DynamicSplitter";
import MonumentsRightPane from "../components/Monuments/MonumentsRightPane";
import MonumentsLeftPane from "../components/Monuments/MonumentsLeftPane";

function Monuments() {
  const [dataForTheLeftPane, setDataForTheLeftPane] = useState();
  const [loading, setLoading] = useState(false);

  return (
    <DynamicSplitter
      ComponentA={<MonumentsLeftPane dataForTheLeftPane={dataForTheLeftPane} />}
      ComponentB={
        <MonumentsRightPane setDataForTheLeftPane={setDataForTheLeftPane} />
      }
      initialOpen={false}
      backgroundColor={"#E6E7E8"}
    />
  );
}

export default Monuments;
