import React, { useEffect, useState } from "react";

function DynamicSplitter({
  ComponentA,
  ComponentB,
  backgroundColor,
  initialOpen,
}) {
  const [open, setOpen] = useState();
  const updatedComponentB = React.cloneElement(ComponentB, {
    setOpen: setOpen,
    open: open,
  });

  useEffect(() => {
    setOpen(initialOpen);
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          flex: 1,
          height: "100vh",
          backgroundColor: backgroundColor || "white",
          display: open ? "" : "none",
          width: open ? "0%" : "50%",
        }}
      >
        {ComponentA}
      </div>
      <div
        style={{
          flex: 1,
          width: open ? "50%" : "0%",
        }}
      >
        {updatedComponentB}
        <div
          className="close-open-left-pane"
          onClick={() => {
            setOpen(!open);
          }}
        >
          {open ? <img src="/close.svg"></img> : <img src="/open.svg"></img>}
        </div>
      </div>
    </div>
  );
}

export default DynamicSplitter;
