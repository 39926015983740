import React, { useState, useEffect } from "react";
import { MapContainer, Marker, Popup } from "react-leaflet";
import { TileLayer } from "react-leaflet/TileLayer";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { renderToString } from "react-dom/server";
import axios from "axios";
import StaticTimeline from "./StaticTimeline";
import Event from "./Event";
import keyEvents from "./events";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";

import {
  buildingRequest,
  pointsOfInterestRequest,
} from "../../services/MonumentsActivityContext/monuments.context";
import MonumentsMarker from "./MonumentsMarker";
import PointsMarker from "./PointsMarker";

const {
  REACT_APP_MAPBOX_USERNAME,
  REACT_APP_MAPBOX_TILE_ID,
  REACT_APP_MAPBOX_API_KEY,
} = process.env;

function RightPane({ setDataForTheLeftPane, setOpen, open }) {
  const [activatedIndex, setActivatedIndex] = useState();
  const [monuments, setMonuments] = useState([]);
  const [pointsOfInterest, setPointsOfInterest] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  const handleResize = () => {
    if (window.innerWidth <= 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    let year = 1960;
    if (isMobile) {
      year = 2020;
    }
    buildingRequest(year).then((data) => setMonuments(data));
    pointsOfInterestRequest().then((data) => setPointsOfInterest(data));
  }, [isMobile]);

  useEffect(() => {
    if (activatedIndex != null) {
      buildingRequest(keyEvents[activatedIndex].year).then((data) =>
        setMonuments(data)
      );
    }
  }, [activatedIndex]);

  const navigate = useNavigate();

  const bounds = [
    [35.1608, 33.3246], // Southwest coordinates (latitude, longitude)
    [35.1801, 33.3762], // Northeast coordinates (latitude, longitude)
  ];

  return (
    <div>
      <MapContainer
        style={{ height: "100vh", width: "100%", zIndex: 0 }}
        center={[35.1688298302, 33.3473576941]}
        zoom={14}
        bounds={bounds}
      >
        <TileLayer
          url={`https://api.mapbox.com/styles/v1/${REACT_APP_MAPBOX_USERNAME}/${REACT_APP_MAPBOX_TILE_ID}/tiles/256/{z}/{x}/{y}@2x?access_token=${REACT_APP_MAPBOX_API_KEY}`}
          attribution='&copy; <a href="https://www.mapbox.com/">Mapbox</a> contributors'
        />

        {monuments.map((monument, index) => {
          return (
            <MonumentsMarker
              monument={monument}
              index={index}
              setDataForTheLeftPane={setDataForTheLeftPane}
              setOpen={setOpen}
            />
          );
        })}

        {pointsOfInterest.map((pointOfInterest, index) => {
          return (
            <PointsMarker
              pointOfInterest={pointOfInterest}
              index={index}
              setDataForTheLeftPane={setDataForTheLeftPane}
              setOpen={setOpen}
            />
          );
        })}

        {activatedIndex != null && !open && (
          <div className="position-event-on-map">
            <Event
              activatedIndex={activatedIndex}
              setActivatedIndex={setActivatedIndex}
            />
          </div>
        )}

        {!open && (
          <div className="position-timeline-on-map">
            <StaticTimeline
              activatedIndex={activatedIndex}
              setActivatedIndex={setActivatedIndex}
            />
          </div>
        )}

        {!open && (
          <img
            className="position-logo-on-map"
            onClick={() => {
              navigate("/");
            }}
            src="/deep_nic_yellow.png"
          />
        )}

        <div className="position-buttons-on-the-map">
          <Button
            onClick={() => {
              navigate("/census");
            }}
            className="population-census-button-map-monument"
          >
            Demography
          </Button>
          <Button
            onClick={() => {
              navigate("/business");
            }}
            className="business-activity-button-map-monument"
          >
            Business Activity
          </Button>
        </div>
      </MapContainer>
    </div>
  );
}

export default RightPane;
