import React, { useState, useEffect, useRef } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import SelectYearForEstablishments from "./SelectYearForEstablishments";
import SelectSector from "./SelectSector";
import RegionsMultiSelect from "./RegionsMultiSelect";

import {
  requestEstablishments,
  formatEstablishmentData,
} from "../../services/BusinessActivityContext/business.context";

import SideNote from "./SideNote";

function BusinessActivityLeftPaneTabEstablishments({
  setDataForTheRightPane,
  setLoading,
}) {
  const [years, setYears] = useState([]);
  const [regions, setRegions] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [dataMap, setDataMap] = useState();

  const [selectedYear, setSelectedYear] = useState();
  const [selectedSector, setSelectedSector] = useState();
  const [selectedRegionName, setSelectedRegionName] = useState();
  const [error, setError] = useState("");

  const [disableButton, setDisableButton] = useState(true);

  useEffect(() => {
    requestEstablishments().then((data) => {
      let object = formatEstablishmentData(data);
      setYears(
        Array.from(object.years).sort(
          (a, b) => parseInt(a.year) - parseInt(b.year)
        )
      );
      setRegions(object.regions);
      setSectors(object.sectors);
      setDataMap(data);
    });
  }, []);

  return (
    <>
      <Row xs="auto">
        <Col className="ms-0" xs="auto">
          <div className="business-search-title">Search</div>
          <div>
            <span className="fill-error">{error}</span>
          </div>
        </Col>
      </Row>
      <Row className="mb-2" xs="auto">
        <Col xs="auto">
          <SelectYearForEstablishments
            title={"Year"}
            arrayMap={years}
            setSelectedYear={setSelectedYear}
          />
        </Col>
        <Col xs="auto">
          <SelectSector
            title={"Sectors"}
            isDisabled={false}
            arrayMap={sectors}
            setDisableButton={setDisableButton}
            setSelectedSector={setSelectedSector}
          />
        </Col>
      </Row>
      <Row className="mt-2" xs="auto">
        <Col xs="auto">
          <RegionsMultiSelect
            arrayMap={regions}
            setSelectedRegionName={setSelectedRegionName}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            disabled={disableButton}
            className="apply-filters-button"
            size="lg"
            onClick={() => {
              let error = "";
              if (selectedYear == null) {
                error += "select year, ";
              }
              if (selectedRegionName == null) {
                error += "select region(s) ";
              }
              if (error !== "") {
                error += "and then proceed";
                setError(error);
                return;
              } else {
                let data = {
                  data: dataMap,
                  region: selectedRegionName,
                  sector: selectedSector,
                  year: selectedYear,
                };
                setLoading(true);
                setDataForTheRightPane(data);
                setError();
              }
            }}
          >
            APPLY FILTERS
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          {selectedYear && (
            <span className="selected-filters">{selectedYear}</span>
          )}
        </Col>
        {selectedRegionName &&
          !selectedRegionName.includes("All") &&
          selectedRegionName.map((region) => (
            <Col xs="auto">
              <span className="selected-filters">{region}</span>
            </Col>
          ))}
        {selectedRegionName && selectedRegionName.includes("All") && (
          <Col xs="auto">
            <span className="selected-filters">All</span>
          </Col>
        )}
        <Col xs="auto">
          {selectedSector && (
            <span className="selected-filters">{selectedSector}</span>
          )}
        </Col>
      </Row>
      <Row>
        <SideNote />
      </Row>
    </>
  );
}

export default BusinessActivityLeftPaneTabEstablishments;
