import React from "react";
import { Navbar, Nav } from "react-bootstrap";

function HorizontalNavBar() {
  return (
    <Navbar className="router-nav" expand="lg">
      <Navbar.Brand href="#"></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="ml-auto" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav className="custom-nav">
          <Nav.Link className="router-link" href="/">
            HOME
          </Nav.Link>
          <Nav.Link className="router-link" href="/aboutus">
            ABOUT
          </Nav.Link>
          <Nav.Link className="router-link" href="/resources">
            RESOURCES
          </Nav.Link>
          <Nav.Link className="router-link" href="/contactus">
            CONTACT US
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default HorizontalNavBar;
