import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";

function FooterNavBar() {
  const navigate = useNavigate();

  return (
    <>
      <Row md={12} style={{ background: "#6100B5", width: "100%" }}>
        <Col md={2} className="footer-images-container">
          <img src="/footer_01.svg" alt="Logo" className="footer-image"></img>
        </Col>
        <Col md={2} className="footer-images-container">
          <img src="/footer_02.svg" alt="Logo" className="footer-image"></img>
        </Col>
        <Col md={1} className="footer-images-container">
          <img src="/footer_03.svg" alt="Logo" className="footer-image"></img>
        </Col>
        <Col md={1} className="footer-images-container">
          <img src="/footer_04.svg" alt="Logo" className="footer-image"></img>
        </Col>
        <Col md={4} className="footer-notices">
          <Row md={12}>
            <p className="footer-notices-paragraph">
              This project has received funding from the Research and Promotion
              Foundation’s, RESTART «Excellence Hubs (Bridge Programmes)» and
              Pillar IV - Bridge Programmes, RIF Proposal No:
              EXCELLENCE/0421/0566{" "}
            </p>
          </Row>
        </Col>
      </Row>
      <Row md={12} style={{ background: "#6100B5" }}>
        <Col md={6}></Col>
        <Col md={5}>
          <Row md={12} style={{ marginBottom: 32 }}>
            <Col>
              <div className="footer-links">Terms & conditions</div>
            </Col>
            <Col>
              <div className="footer-links">Privacy policy</div>
            </Col>
            <Col>
              <div className="footer-links">Takedown policy</div>
            </Col>
            <Col>
              <div
                className="footer-links"
                onClick={() => navigate("/disclaimer")}
              >
                Disclaimer
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default FooterNavBar;
