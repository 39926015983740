import React from "react";
import Form from "react-bootstrap/Form";

function SelectYear({
  setDisableGenderSelect,
  setSelectedRegionName,
  title,
  isDisabled,
  arrayMap,
  setSelectedRegion,
  regionRef,
  sectorRef,
  setSelectedSector,
}) {
  return (
    <Form.Select
      aria-label={title}
      ref={regionRef}
      defaultValue={"Region"}
      disabled={isDisabled}
      className="business-select"
      onChange={(e) => {
        setSelectedRegion(e.target.value);
        setSelectedRegionName(e.target.options[e.target.selectedIndex].text);
        setDisableGenderSelect(false);
        if (sectorRef != null) {
          setSelectedSector();
          sectorRef.current.value = "Sectors";
        }
      }}
    >
      <option hidden>{title}</option>
      {arrayMap.map((region, index) => (
        <option
          key={index}
          className="background-color-options"
          value={region.id}
        >
          {region.name}
        </option>
      ))}
    </Form.Select>
  );
}

export default SelectYear;
