import React from "react";

import { BsXLg } from "react-icons/bs";

import keyEvents from "./events";

function Event({ activatedIndex, setActivatedIndex }) {
  return (
    <>
      <div className="event-title-div">
        <div class="event-title">{keyEvents[activatedIndex].title}</div>
        <BsXLg
          className="info-close-button"
          onClick={() => {
            setActivatedIndex(null);
          }}
        />
      </div>
      <div className="event-description-div">
        {keyEvents[activatedIndex].description}
      </div>
      {activatedIndex == 0 ? (
        <div
          className="next"
          onClick={() => {
            setActivatedIndex(activatedIndex + 1);
          }}
        >
          next
        </div>
      ) : activatedIndex == 9 ? (
        <div
          className="next-previous"
          onClick={() => {
            setActivatedIndex(activatedIndex - 1);
          }}
        >
          previous
        </div>
      ) : (
        <div className="div-next-previous">
          <div
            className="next-previous"
            onClick={() => {
              setActivatedIndex(activatedIndex - 1);
            }}
          >
            previous
          </div>
          <div
            className="next-previous"
            onClick={() => {
              setActivatedIndex(activatedIndex + 1);
            }}
          >
            next
          </div>
        </div>
      )}
    </>
  );
}

export default Event;
