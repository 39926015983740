import React from "react";

import Form from "react-bootstrap/Form";

function CensusYearSelectAges({ title, yearRef, arrayMap, setSelectedYear }) {
  return (
    <Form.Select
      aria-label={title}
      className="census-select"
      ref={yearRef}
      onChange={(e) => {
        setSelectedYear(e.target.value);
      }}
    >
      <option hidden>{title}</option>
      {arrayMap.map((year, index) => (
        <option key={index} className="background-color-options" value={year}>
          {year}
        </option>
      ))}
    </Form.Select>
  );
}

export default CensusYearSelectAges;
