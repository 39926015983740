import React, { useState } from "react";
import DynamicSplitter from "../components/Core/DynamicSplitter";
import CensusRightPane from "../components/Census Components/CensusRightPane";
import CensusLeftPane from "../components/Census Components/CensusLeftPane";

function Census() {
  const [dataForTheRightPane, setDataForTheRightPane] = useState();
  const [loading, setLoading] = useState(false);

  return (
    <DynamicSplitter
      ComponentA={
        <CensusLeftPane
          setDataForTheRightPane={setDataForTheRightPane}
          setLoading={setLoading}
        />
      }
      ComponentB={
        <CensusRightPane
          dataForTheRightPane={dataForTheRightPane}
          setLoading={setLoading}
          loading={loading}
        />
      }
      backgroundColor="#697378"
      initialOpen={true}
    />
  );
}

export default Census;
