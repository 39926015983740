import { createBrowserRouter } from "react-router-dom";

import NotFound from "./views/NotFound";
import HomePage from "./views/HomePage";
import AboutUs from "./views/AboutUs";
import ContactUs from "./views/ContactUs";
import Resources from "./views/Resources";
import Selection from "./views/Selection";
import Census from "./views/Census";
import BusinessActivity from "./views/BusinessActivity";
import Monuments from "./views/Monuments";
import Disclaimer from "./views/Disclaimer";

const router = createBrowserRouter([
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/aboutus",
    element: <AboutUs />,
  },
  {
    path: "/contactus",
    element: <ContactUs />,
  },
  {
    path: "/resources",
    element: <Resources />,
  },
  {
    path: "/selection",
    element: <Selection />,
  },
  {
    path: "/census",
    element: <Census />,
  },
  {
    path: "/business",
    element: <BusinessActivity />,
  },
  {
    path: "/monuments",
    element: <Monuments />,
  },
  {
    path: "/disclaimer",
    element: <Disclaimer />,
  },
]);

export default router;
