import React from "react";

function StaticTimeline({ activatedIndex, setActivatedIndex }) {
  return (
    <div className="years-div">
      <p
        onClick={() => {
          setActivatedIndex(0);
        }}
        className={activatedIndex == 0 ? "years-activated" : "years"}
      >
        1960
      </p>
      <p
        onClick={() => {
          setActivatedIndex(1);
        }}
        className={activatedIndex == 1 ? "years-activated" : "years"}
      >
        1963-1974
      </p>
      <p
        onClick={() => {
          setActivatedIndex(2);
        }}
        className={activatedIndex == 2 ? "years-activated" : "years"}
      >
        1974
      </p>
      <p
        onClick={() => {
          setActivatedIndex(3);
        }}
        className={activatedIndex == 3 ? "years-activated" : "years"}
      >
        1979
      </p>
      <p
        onClick={() => {
          setActivatedIndex(4);
        }}
        className={activatedIndex == 4 ? "years-activated" : "years"}
      >
        1983
      </p>
      <p
        onClick={() => {
          setActivatedIndex(5);
        }}
        className={activatedIndex == 5 ? "years-activated" : "years"}
      >
        1990
      </p>
      <p
        onClick={() => {
          setActivatedIndex(6);
        }}
        className={activatedIndex == 6 ? "years-activated" : "years"}
      >
        2003
      </p>
      <p
        onClick={() => {
          setActivatedIndex(7);
        }}
        className={activatedIndex == 7 ? "years-activated" : "years"}
      >
        2004
      </p>
      <p
        onClick={() => {
          setActivatedIndex(8);
        }}
        className={activatedIndex == 8 ? "years-activated" : "years"}
      >
        2013
      </p>
      <p
        onClick={() => {
          setActivatedIndex(9);
        }}
        className={activatedIndex == 9 ? "years-activated" : "years"}
      >
        2020
      </p>
    </div>
  );
}

export default StaticTimeline;
