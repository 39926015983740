import React from "react";
import { motion } from "framer-motion";

import YourSVGComponent from "./customSvg";

function FrontPageImage({ picture, credits, secondCredit }) {
  return (
    <div
      style={{
        maxWidth: "100%",
        width: "100vw",
        height: "100vh",
        background: `url(/${picture})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <img
        className="first-page-logo"
        src="/deep_nic_yellow.png"
        alt="DeepNic Logo Yellow"
      ></img>
      <a href="/selection">
        <YourSVGComponent
          style={{
            height: "auto",
            position: "absolute",
            bottom: "20%",
            right: "15%",
            maxWidth: "25%",
          }}
          classNam={"go-to-map-button"}
        />
      </a>
      <div className="picture-credits">
        {credits == "" ? null : credits}
        {secondCredit == "" ? null : (
          <>
            <br></br>
            <span style={{ fontSize: 10 }}>{secondCredit}</span>
          </>
        )}
      </div>
    </div>
  );
}

export default FrontPageImage;
