import React from "react";
import axios from "axios";

export const yearRequest = () => {
  let response = axios
    .post(`${process.env.REACT_APP_API_BASE_URL}/api/index.php`, {
      executeQuery: "businessActivityEmploymentYears",
      apiKey: process.env.REACT_APP_API_KEY,
    })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
  return response;
};

export const regionRequest = (year) => {
  let response = axios
    .post(`${process.env.REACT_APP_API_BASE_URL}/api/index.php`, {
      executeQuery: "businessActivityEmploymentRegions",
      apiKey: process.env.REACT_APP_API_KEY,
      dataObject: { year: year },
    })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
  return response;
};

export const sectorsRequest = (year, regionID) => {
  let response = axios
    .post(`${process.env.REACT_APP_API_BASE_URL}/api/index.php`, {
      executeQuery: "businessActivityEmployment",
      apiKey: process.env.REACT_APP_API_KEY,
      dataObject: { year: year, regionID: regionID },
    })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
  return response;
};

export const transformSectors = (data) => {
  const arraySectors = Object.keys(data[0]);
  var sortedSectors = arraySectors.sort();

  sortedSectors = sortedSectors.map((sentence) => sentence.replace("Men", ""));
  sortedSectors = sortedSectors.map((sentence) =>
    sentence.replace("Women", "")
  );

  sortedSectors = sortedSectors.map((sentence) =>
    sentence.replace("Totals", "")
  );

  const finalArray = Array.from(new Set(sortedSectors));
  return finalArray;
};

export const employmentDataPresenation = (data, gender, sector) => {
  const correctDataArray = [];

  correctDataArray.push([
    "Element",
    `values`,
    { role: "style" },
    { role: "tooltip" },
  ]);

  let i = 1;
  let selectedSectorPosition;

  for (const key of Object.keys(data)) {
    if (key.includes(gender)) {
      const value = parseInt(data[key]);
      const color = "#e9ffdb";
      //const color = "#00FF97";
      const finalName = key.replace(gender, "");
      if (finalName == sector) {
        selectedSectorPosition = i;
      }
      correctDataArray.push([
        i.toString(),
        value,
        color,
        `Sector: ${finalName}\n ${
          gender == "Women" ? "Female" : "Male"
        }: ${value}`,
      ]);
      i++;
    }
  }

  const row = correctDataArray[selectedSectorPosition];
  row[2] = "#00FF97";
  correctDataArray.splice(selectedSectorPosition, 1);
  correctDataArray.splice(1, 0, row);

  return correctDataArray;
};

export const getColor = (d) => {
  return d > 3000
    ? "#265253"
    : d > 2000
    ? "#265B58"
    : d > 1000
    ? "#286E63"
    : d > 800
    ? "#29816C"
    : d > 600
    ? "#2C9576"
    : d > 400
    ? "#54AC8C"
    : d > 200
    ? "#37BF8A"
    : d > 100
    ? "#3DD194"
    : d > 50
    ? "#43E49E"
    : "#00FF97";
};

export const getPositions = (textPosition) => {
  var result = textPosition.split(" ");
  result = result.map((element) => {
    let x = element.split(",");
    let ret = [x[1], x[0]];
    return ret;
  });
  return result;
};

export const requestEstablishments = () => {
  let response = axios
    .post(`${process.env.REACT_APP_API_BASE_URL}/api/index.php`, {
      executeQuery: "businessActivityEstablishments",
      apiKey: process.env.REACT_APP_API_KEY,
    })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
  return response;
};

export const formatEstablishmentData = (data) => {
  const yearsArray = data.map((element) => {
    const { year } = element;
    return { year };
  });
  const yearsArrayUnique = yearsArray.filter(isUnique);

  const regionsArray = data.map((element) => element.name);
  const regionsArayUnique = Array.from(new Set(regionsArray));

  const sectors = data[0];
  const attributesToDelete = ["name", "outline", "year"];
  attributesToDelete.forEach((attr) => delete sectors[attr]);

  const sectorsArray = Object.keys(sectors);

  const formatedObject = {
    years: yearsArrayUnique,
    regions: regionsArayUnique,
    sectors: sectorsArray,
  };

  return formatedObject;
};

function isUnique(obj, index, self) {
  return index === self.findIndex((o) => o.year === obj.year);
}

export const filterEstablishedData = (data) => {
  const allData = data.data;
  const regions = data.region;
  const sector = data.sector;
  const year = data.year;

  var threwRegionsNotMatch = allData;

  if (!regions.includes("All")) {
    threwRegionsNotMatch = allData.filter((element) =>
      regions.includes(element.name)
    );
  }

  const threwYearsNotMatch = threwRegionsNotMatch.filter(
    (element) => element.year == year
  );

  const threwUnnessesarySectors = threwYearsNotMatch.map((element) => ({
    name: element.name,
    outline: element.outline,
    number: element[sector],
    sector: sector,
  }));

  return threwUnnessesarySectors;
};
