import React, { useRef, useState } from "react";
import FrontPageUpper from "../components/Core/FrontPageUpper";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import FooterNavBar from "../components/NavBars/FooterNavBar";
import axios from "axios";

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

function ContactUs() {
  const iconCyens = new L.Icon({
    iconUrl: "/Cyens_Marker.png",
    iconRetinaUrl: "/Cyens_Marker.png",
    popupAnchor: [-0, -0],
    iconSize: [32, 45],
  });

  const nameRef = useRef();
  const surnameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const messageRef = useRef();

  const [message, setMessage] = useState();

  return (
    <>
      <FrontPageUpper
        picture={"contactus_image.jpg"}
        credits={""}
        secondCredit={""}
      />
      <Container fluid style={{ background: "#C7C9C9" }}>
        <Row>
          <Col className="ms-5 mt-2 mb-2">
            <p className="title">CONTACT US</p>
          </Col>
        </Row>
        <Row>
          <Col className="ms-5 mt-1 me-2" xs="auto">
            <h4 className="subtitle">LOCATION</h4>
            <p className="loc-paragraph">
              Dimarchou Lellou Demetriadi
              <br></br>
              (formerly Dimarchias Square) 23
              <br></br>
              Nicosia 1016
              <br></br>
              Cyprus
              <br></br>
              Phone:<span style={{ fontWeight: 600 }}> +357 22 747575</span>
              <br></br>
              E-Mail:
              <span style={{ fontWeight: 600 }}> info@cyens.org.cy</span>
            </p>
          </Col>
          <Col className="ms-5">
            <Row>
              <h4 className="subtitle">CONTACT FORM</h4>
              {message && (
                <h6
                  style={{
                    color:
                      message === "Email sent successfully." ? "green" : "red",
                  }}
                >
                  {message}
                </h6>
              )}
            </Row>
            <Row>
              <Col md={2}>
                <Form.Control
                  size="md"
                  id="name"
                  type="text"
                  placeholder="Name"
                  className="simple-corners"
                  ref={nameRef}
                />
              </Col>
              <Col md={2}>
                <Form.Control
                  size="md"
                  id="surname"
                  type="text"
                  placeholder="Surname"
                  className="simple-corners"
                  ref={surnameRef}
                />
              </Col>
              <Col md={2}>
                <Form.Control
                  size="md"
                  id="email"
                  type="email"
                  placeholder="Email"
                  className="simple-corners"
                  ref={emailRef}
                />
              </Col>
              <Col md={2}>
                <Form.Control
                  size="md"
                  id="phone"
                  type="tel"
                  placeholder="Phone"
                  className="simple-corners"
                  ref={phoneRef}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Control
                  as="textarea"
                  rows={6}
                  className="text-area-width"
                  placeholder="Message"
                  ref={messageRef}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  className="form-submit-button"
                  type="button"
                  onClick={() => {
                    axios
                      .post(
                        `${process.env.REACT_APP_API_BASE_URL}/api/email/send_email.php`,
                        {
                          name: nameRef.current.value,
                          surname: surnameRef.current.value,
                          email: emailRef.current.value,
                          phone: phoneRef.current.value,
                          message: messageRef.current.value,
                        }
                      )
                      .then(function (response) {
                        nameRef.current.value = "";
                        surnameRef.current.value = "";
                        emailRef.current.value = "";
                        phoneRef.current.value = "";
                        messageRef.current.value = "";
                        setMessage(response.data);
                      })
                      .catch(function (error) {
                        console.log(error);
                      });
                  }}
                >
                  SUBMIT
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="contact-us-map">
                  <MapContainer
                    style={{ height: 400, width: "90%" }}
                    center={[35.1740793, 33.3647767]}
                    zoom={21}
                    scrollWheelZoom={true}
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker
                      position={[35.1740793, 33.3647767]}
                      icon={iconCyens}
                    >
                      <Popup>CYENS - Centre of Excellence</Popup>
                    </Marker>
                  </MapContainer>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <FooterNavBar />
      </Container>
    </>
  );
}

export default ContactUs;
