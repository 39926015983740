import React from "react";

import Form from "react-bootstrap/Form";

function SelectYearForEstablishments({ title, arrayMap, setSelectedYear }) {
  return (
    <Form.Select
      aria-label={title}
      className="business-select"
      onChange={(e) => {
        setSelectedYear(e.target.value);
      }}
    >
      <option hidden>{title}</option>
      {arrayMap.map((year, index) => (
        <option className="background-color-options" value={year.year}>
          {year.year}
        </option>
      ))}
    </Form.Select>
  );
}

export default SelectYearForEstablishments;
