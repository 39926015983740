import React, { useState, useEffect, useRef } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import SelectYear from "./SelectYear";
import SelectRegion from "./SelectRegion";
import SelectGender from "./SelectGender";
import SelectSector from "./SelectSector";

import {
  yearRequest,
  regionRequest,
  sectorsRequest,
  transformSectors,
} from "../../services/BusinessActivityContext/business.context";
import SideNote from "./SideNote";

function BusinessActivityLeftPaneTabEmployment({
  setDataForTheRightPane,
  setLoading,
}) {
  const [years, setYears] = useState([]);
  const [regions, setRegions] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [dataMap, setDataMap] = useState();

  const [selectedYear, setSelectedYear] = useState();
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedGender, setSelectedGender] = useState();
  const [selectedSector, setSelectedSector] = useState();
  const [selectedRegionName, setSelectedRegionName] = useState();

  const [disableRegionSelect, setDisableRegionSelect] = useState(true);
  const [disableGenderSelect, setDisableGenderSelect] = useState(true);
  const [disableSectorSelect, setDisableSectorSelect] = useState(true);
  const [disableButton, setDisableButton] = useState(true);

  const [error, setErrorMessage] = useState("");

  const genderRef = useRef();
  const regionRef = useRef();
  const sectorRef = useRef();
  const yearRef = useRef();

  useEffect(() => {
    yearRequest().then((data) => {
      setYears(
        Array.from(data).sort((a, b) => parseInt(a.year) - parseInt(b.year))
      );
    });
  }, []);

  useEffect(() => {
    regionRequest(selectedYear).then((data) => {
      setRegions(
        Array.from(data).sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
      );
    });
  }, [selectedYear]);

  useEffect(() => {
    if (selectedRegion != null) {
      sectorsRequest(selectedYear, selectedRegion).then((data) => {
        setDataMap(data[0]);
        let finalSectors = transformSectors(data);
        setSectors(finalSectors);
      });
    }
  }, [selectedRegion]);

  return (
    <>
      <Row xs="auto">
        <Col className="ms-0" xs="auto">
          <div className="business-search-title">Search</div>
          <div>
            <span className="fill-error">{error}</span>
          </div>
        </Col>
      </Row>
      <Row className="mb-2" xs="auto">
        <Col xs="auto">
          <SelectYear
            setDisableRegionSelect={setDisableRegionSelect}
            title={"Year"}
            arrayMap={years}
            setSelectedYear={setSelectedYear}
            genderRef={genderRef}
            regionRef={regionRef}
            sectorRef={sectorRef}
            yearRef={yearRef}
            setDisableButton={setDisableButton}
            setSelectedGender={setSelectedGender}
            setSelectedRegion={setSelectedRegion}
            setSelectedRegionName={setSelectedRegionName}
            setSelectedSector={setSelectedSector}
          />
        </Col>
        <Col xs="auto">
          <SelectRegion
            regionRef={regionRef}
            setDisableGenderSelect={setDisableGenderSelect}
            title={"Region"}
            isDisabled={disableRegionSelect}
            setSelectedRegionName={setSelectedRegionName}
            arrayMap={regions}
            setSelectedRegion={setSelectedRegion}
            sectorRef={sectorRef}
            setSelectedSector={setSelectedSector}
          />
        </Col>
      </Row>
      <Row className="mt-2" xs="auto">
        <Col xs="auto">
          <SelectGender
            genderRef={genderRef}
            selectedRegion={selectedRegion}
            setSelectedGender={setSelectedGender}
            title={"Gender"}
            isDisabled={disableGenderSelect}
            setDisableSectorSelect={setDisableSectorSelect}
          />
        </Col>
        <Col xs="auto">
          <SelectSector
            sectorRef={sectorRef}
            title={"Sectors"}
            isDisabled={disableSectorSelect}
            arrayMap={sectors}
            setDisableButton={setDisableButton}
            setSelectedSector={setSelectedSector}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            disabled={disableButton}
            className="apply-filters-button"
            size="lg"
            onClick={() => {
              let error = "";
              if (selectedGender == null) {
                error += "select gender, ";
              }
              if (selectedRegionName == null) {
                error += "select region, ";
              }
              if (selectedSector == null) {
                error += "select sector, ";
              }
              if (error !== "") {
                error += " and then proceed";
                setErrorMessage(error);
                return;
              } else {
                setErrorMessage("");

                regionRef.current.value = "Region";
                genderRef.current.value = "Gender";
                sectorRef.current.value = "Sectors";
                yearRef.current.value = "Year";
                setDisableGenderSelect(true);
                setDisableRegionSelect(true);
                setDisableSectorSelect(true);
                setDisableButton(true);

                let data = {
                  data: dataMap,
                  gender: selectedGender,
                  region: selectedRegionName,
                  sector: selectedSector,
                  year: selectedYear,
                };
                setLoading(true);
                setDataForTheRightPane(data);
                setErrorMessage();
              }
            }}
          >
            APPLY FILTERS
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          {selectedYear && (
            <span className="selected-filters">{selectedYear}</span>
          )}
        </Col>
        <Col xs="auto">
          {selectedRegion && (
            <span className="selected-filters">{selectedRegionName}</span>
          )}
        </Col>
        <Col xs="auto">
          {selectedGender && (
            <span className="selected-filters">
              {selectedGender == "Women"
                ? "Female"
                : selectedGender == "Men"
                ? "Male"
                : "Totals"}
            </span>
          )}
        </Col>
        <Col xs="auto">
          {selectedSector && (
            <span className="selected-filters">{selectedSector}</span>
          )}
        </Col>
      </Row>
      <Row>
        <SideNote />
      </Row>
    </>
  );
}

export default BusinessActivityLeftPaneTabEmployment;
