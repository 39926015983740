import React from "react";
import HorizontalNavBar from "../NavBars/HorizontalNavBar";
import VerticalNavBar from "../NavBars/VerticalNavBar";
import FrontPageImage from "./FrontPageImage";

function FrontPageUpper({ picture, credits, secondCredit }) {
  return (
    <div>
      <HorizontalNavBar />
      <FrontPageImage
        picture={picture}
        credits={credits}
        secondCredit={secondCredit}
      />
      <VerticalNavBar />
    </div>
  );
}

export default FrontPageUpper;
