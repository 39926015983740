import React from "react";

import BusinessActivityEmployment from "./BusinessActivityEmployment";
import BusinessActivityEstablishments from "./BusinessActivityEstablishments";

import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";

function BusinessRightPane({ dataForTheRightPane, setLoading, loading, open }) {
  const navigate = useNavigate();

  if (dataForTheRightPane == null) {
    return (
      <div className="business-totals-div">
        <div className="position-buttons-on-the-map-business">
          <Button
            onClick={() => {
              navigate("/census");
            }}
            className="population-census-button-map"
          >
            Demography
          </Button>
          <Button
            onClick={() => {
              navigate("/monuments");
            }}
            className="monuments-button-map"
          >
            Monument Building
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div
      className={
        dataForTheRightPane.region == "Cyprus" ||
        dataForTheRightPane.region == "Nicosia" ||
        dataForTheRightPane.region == "Turkish-Cypriot Community"
          ? "business-totals-div"
          : null
      }
    >
      {dataForTheRightPane.region == "Cyprus" ||
      dataForTheRightPane.region == "Nicosia" ||
      dataForTheRightPane.region == "Turkish-Cypriot Community" ? (
        <BusinessActivityEmployment
          data={dataForTheRightPane}
          setLoading={setLoading}
          loading={loading}
        />
      ) : (
        <BusinessActivityEstablishments
          data={dataForTheRightPane}
          setLoading={setLoading}
          loading={loading}
          open={open}
        />
      )}
    </div>
  );
}

export default BusinessRightPane;
