import React from "react";

import Form from "react-bootstrap/Form";

function SelectYear({
  setDisableRegionSelect,
  title,
  arrayMap,
  setSelectedYear,
  genderRef,
  sectorRef,
  regionRef,
  yearRef,
  setDisableButton,
  setSelectedGender,
  setSelectedRegion,
  setSelectedRegionName,
  setSelectedSector,
}) {
  return (
    <Form.Select
      aria-label={title}
      className="business-select"
      style={{ background: "#00FF97" }}
      ref={yearRef}
      onChange={(e) => {
        if (regionRef) {
          regionRef.current.value = "Region";
        }
        if (genderRef) {
          genderRef.current.value = "Gender";
        }
        if (sectorRef) {
          sectorRef.current.value = "Sectors";
        }
        setSelectedGender();
        setSelectedRegion();
        setSelectedRegionName();
        setSelectedSector();
        setSelectedYear(e.target.value);
        setDisableRegionSelect(false);
        setDisableButton(true);
      }}
    >
      <option hidden>{title}</option>
      {arrayMap.map((year, index) => (
        <option
          key={index}
          className="background-color-options"
          value={year.year}
        >
          {year.year}
        </option>
      ))}
    </Form.Select>
  );
}

export default SelectYear;
