import React, { useState, useEffect, useRef } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import {
  censusRequestNationalityYears,
  censusRequestNationalityRegions,
  censusRequestNationalityNationalities,
} from "../../services/CensusActivityContext/census.context";

import CensusYearSelectNationality from "./CensusYearSelectNationality";
import CensusAgeNationalitySelect from "./CensusAgeNationalitySelect";
import CensusRegionSelect from "./CensusRegionSelect";
import SideNote from "./SideNote";

function CensusLeftPaneTabNationality({ setLoading, setDataForTheRightPane }) {
  const [error, setError] = useState("");
  const [years, setYears] = useState([]);
  const [regions, setRegions] = useState([]);
  const [agesNationalities, setAgesNationalities] = useState([]);
  const [dataMap, setDataMap] = useState([]);

  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedRegionName, setSelectedRegionName] = useState(null);
  const [selectedAgeNationality, setSelectedAgeNationality] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [disableButton, setDisableButton] = useState(true);

  const yearRef = useRef();
  const ageNationalityRef = useRef();
  const regionRef = useRef();

  useEffect(() => {
    censusRequestNationalityYears().then((data) => {
      setYears(
        data.map((obj) => obj.year).sort((a, b) => parseInt(a) - parseInt(b))
      );
    });
  }, []);

  useEffect(() => {
    if (selectedYear != undefined) {
      censusRequestNationalityRegions(selectedYear).then((data) => {
        setRegions(data);
      });
    }
  }, [selectedYear]);

  useEffect(() => {
    if (selectedRegion != undefined) {
      censusRequestNationalityNationalities(selectedYear, selectedRegion).then(
        (data) => {
          console.log(data);
          setDataMap(data.dataMap);
          setAgesNationalities(data.nationalities);
        }
      );
    }
  }, [selectedRegion]);

  return (
    <>
      <Row xs="auto">
        <Col className="ms-0" xs="auto">
          <div className="business-search-title">Search</div>
          <div>
            <span className="fill-error">{error}</span>
          </div>
        </Col>
      </Row>
      <Row className="mb-2" xs="auto">
        <Col xs="auto">
          <CensusYearSelectNationality
            arrayMap={years}
            title={"Year"}
            setSelectedYear={setSelectedYear}
            yearRef={yearRef}
            regionRef={regionRef}
            setDisableButton={setDisableButton}
            setSelectedRegion={setSelectedRegion}
            setSelectedRegionName={setSelectedRegionName}
            setSelectedAgeNationality={setSelectedAgeNationality}
          />
        </Col>
        <Col xs="auto">
          <CensusRegionSelect
            title={"Region"}
            disabled={true}
            setSelectedRegion={setSelectedRegion}
            setSelectedRegionName={setSelectedRegionName}
            regionRef={regionRef}
            arrayMap={regions}
          />
        </Col>
        <Col xs="auto">
          <CensusAgeNationalitySelect
            arrayMap={agesNationalities}
            disabled={agesNationalities.length == 0}
            title={"Nationality"}
            setSelectedAgeNationality={setSelectedAgeNationality}
            ageNationalityRef={ageNationalityRef}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            className="apply-filters-button"
            disabled={disableButton}
            size="lg"
            onClick={() => {
              let error = "";
              if (
                selectedAgeNationality == null &&
                agesNationalities.length != 0
              ) {
                error += "select nationality, ";
              }
              if (selectedYear == null) {
                error += "select year, ";
              }
              if (selectedRegion == null) {
                error += "select region, ";
              }
              if (error !== "") {
                error += " and then proceed";
                setError(error);
                return;
              } else {
                setError("");

                if (ageNationalityRef.current != null) {
                  ageNationalityRef.current.value = "Nationality";
                }

                yearRef.current.value = "Year";
                regionRef.current.value = "Region";

                let data = {
                  data: dataMap,
                  nationality: selectedAgeNationality || "",
                  year: selectedYear,
                  region: selectedRegion,
                };

                setDisableButton(true);
                setLoading(true);
                setDataForTheRightPane(data);
                setError();
              }
            }}
          >
            APPLY FILTERS
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          {selectedYear && (
            <span className="selected-filters">{selectedYear}</span>
          )}
        </Col>
        <Col xs="auto">
          {selectedAgeNationality && (
            <span className="selected-filters">{selectedAgeNationality}</span>
          )}
        </Col>
        <Col xs="auto">
          {selectedRegionName && (
            <span className="selected-filters">{selectedRegionName}</span>
          )}
        </Col>
      </Row>
      <Row>
        <SideNote />
      </Row>
    </>
  );
}

export default CensusLeftPaneTabNationality;
