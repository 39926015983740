import React from "react";

import Form from "react-bootstrap/Form";

function SelectGender({
  setSelectedGender,
  isDisabled,
  title,
  setDisableSectorSelect,
  selectedRegion,
  genderRef,
}) {
  return (
    <Form.Select
      ref={genderRef}
      aria-label={title}
      disabled={isDisabled}
      className="business-select"
      onChange={(e) => {
        setSelectedGender(e.target.value);
        setDisableSectorSelect(false);
      }}
    >
      <option hidden>{title}</option>
      {selectedRegion != 35 && (
        <option className="background-color-options" value="Women">
          Female
        </option>
      )}
      {selectedRegion != 35 && (
        <option className="background-color-options" value="Men">
          Male
        </option>
      )}
      {selectedRegion == 35 && (
        <option className="background-color-options" value="Totals">
          Totals
        </option>
      )}
    </Form.Select>
  );
}

export default SelectGender;
