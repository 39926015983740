import React, { useState, useEffect, useRef } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useNavigate } from "react-router-dom";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";

import CensusLeftPaneTabAgeGroups from "./CensusLeftPaneTabAgeGroups";
import CensusLeftPaneTabNationality from "./CensusLeftPaneTabNationality";

function CensusLeftPane({ setDataForTheRightPane, setLoading }) {
  const navigate = useNavigate();

  const [value, setValue] = useState("Age");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="selections-area">
      <Container>
        <Row style={{ background: "#697378" }}>
          <Col className="ms-4" xs="auto">
            <img
              className="selections-area-logo"
              onClick={() => {
                navigate("/");
              }}
              src="/deep_nic_yellow.png"
            />
          </Col>
        </Row>
        <Row style={{ overflowX: "hidden" }}>
          <Col className="ms-2 mt-4">
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 3,
                  borderColor: "divider",
                  overflowX: "hidden",
                }}
              >
                <TabList
                  onChange={handleChange}
                  aria-label="tab layout"
                  variant="scrollable"
                  TabIndicatorProps={{
                    sx: {
                      bgcolor: "#FF6969",
                      fontFamily: "'Poppins', sans-serif",
                    },
                  }}
                >
                  <Tab
                    label="Age"
                    value="Age"
                    style={{
                      color: "#697378",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  />
                  <Tab
                    label="Nationality"
                    value="Nationality"
                    style={{
                      color: "#697378",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  />
                </TabList>
              </Box>
              <TabPanel value="Age">
                <CensusLeftPaneTabAgeGroups
                  setDataForTheRightPane={setDataForTheRightPane}
                  setLoading={setLoading}
                />
              </TabPanel>
              <TabPanel value="Nationality">
                <CensusLeftPaneTabNationality
                  setDataForTheRightPane={setDataForTheRightPane}
                  setLoading={setLoading}
                />
              </TabPanel>
            </TabContext>
          </Col>
        </Row>
        <Row style={{ background: "#FF4545" }}>
          <Col className="census-key-events-title ms-4 mt-4 mb=2" xs="auto">
            KEY EVENTS
          </Col>
        </Row>
        <Row
          style={{
            lineHeight: 0.8,
            background: "#FF4545",
          }}
        >
          <Col>
            <div className="ms-4">
              <p className="census-key-year">
                1960
                <br />
                <span className="census-key-description">
                  The establishment of the Republic of Cyprus
                </span>
              </p>
            </div>
            <div className="ms-4">
              <p className="census-key-year">
                1963-74
                <br />
                <span className="census-key-description">
                  Intercommunal strife
                </span>
              </p>
            </div>
            <div className="ms-4">
              <p className="census-key-year">
                1974
                <br />
                <span className="census-key-description">
                  The 1974 war and the division of the island
                </span>
              </p>
            </div>
            <div className="ms-4">
              <p className="census-key-year">
                1979
                <br />
                <span className="census-key-description">
                  The Nicosia Master Plan
                </span>
              </p>
            </div>
          </Col>
          <Col>
            <div className="ms-4">
              <p className="census-key-year">
                1983
                <br />
                <span className="census-key-description">
                  The unilateral proclamation in the Turkish-Cypriot community
                </span>
              </p>
            </div>
            <div className="ms-4">
              <p className="census-key-year">
                1990
                <br />
                <span className="census-key-description">
                  Granting of work permits to third country nationals
                </span>
              </p>
            </div>
            <div className="ms-4">
              <p className="census-key-year">
                2003
                <br />
                <span className="census-key-description">
                  The opening of the crossings
                </span>
              </p>
            </div>
          </Col>
          <Col style={{ marginBottom: 120 }}>
            <div className="ms-4">
              <p className="census-key-year">
                2004
                <br />
                <span className="census-key-description">
                  Cyprus’s accession to the European Union
                </span>
              </p>
            </div>
            <div className="ms-4">
              <p className="census-key-year">
                2013
                <br />
                <span className="census-key-description">
                  The economic crisis
                </span>
              </p>
            </div>
            <div className="ms-4">
              <p className="census-key-year">
                2020
                <br />
                <span className="census-key-description">
                  The Covid-19 pandemic
                </span>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CensusLeftPane;
