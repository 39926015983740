const keyEvents = [
  {
    year: "1960",
    title: "The establishment of the Republic of Cyprus, 1960",
    description:
      "On August 16, 1960, Cyprus became an independent state, after 82 years of British colonial rule. The island’s independence was firstly agreed in Zurich in February 1959, between Great Britain, Greece, and Turkey. The Zurich Agreement were ratified a few days later in London by the representatives of the two communities, Archbishop Makarios III on behalf of the Greek Cypriots and Dr Fazıl Küçük on behalf of the Turkish Cypriots. The Constitution of the new Republic was accompanied by the Treaty of Guarantor powers which provided that Great Britain, Greece, and Turkey would guarantee the independence of Cyprus.",
  },
  {
    year: "1973",
    title: "Intercommunal strife, 1963-1974",
    description:
      "President Makarios' attempts in 1963 to amend certain provisions of the Constitution were rejected by Turkish Cypriots. This became the first of many disputed events in the early years of the Republic. While some saw Makarios’s move as an attempt to prevent deadlock in decision making bodies, others perceived it as a deliberate effort to weaken the position of Turkish Cypriots in the government and to remove them from decision-making bodies. In December 1963, the killing of a Turkish Cypriot by a Greek Cypriot policeman in the walled city of Nicosia ignited violent intercommunal clashes that dragged into the first months of 1964, while after a short impasse, a new round of violence broke out in 1967. In the aftermath of these events, Turkish Cypriots retreated into a series of enclaves, a move that was counteracted with a severe economic embargo. With the intervention of the United Nations, Nicosia was divided into a Greek and Turkish sector, by an UN-patrolled demarcation line, known, since March 1964, as the Green Line. While the embargoes and restrictions to movement were lifted in 1968, Nicosia did not recover from the partition of the city. On the contrary, the participation of Nicosia was consolidated in 1974 and expanded throughout the island. ",
  },
  {
    year: "1974",
    title: "The 1974 war and the division of the island",
    description:
      "Relations between the two communities never really improved, while the dictatorship in Greece and Turkey’s expansionist aspirations resulted in a war that divided the island - a division that is still in force today. The coup to overthrow Makarios in July 1974, led by the Greek Junta gave Turkey the pretext to invade the island based on the Article VI of The Treaty of Guarantee (1960) as justification to protect Turkish Cypriots who were under threat. Within a few days Cyprus was counting a heavy toll: hundreds of people died, or went missing, and thousands lost their houses and became refugees. The geographical division was soon followed by a demographic one; the Green Line was extended to the whole island, while a large Turkish military regiment was established in the northern part of Cyprus. In 1975, the voluntary regrouping of populations was agreed at the Vienna intercommunal talks after which the Turkish Cypriot population in the south moved to the north and Greek Cypriots to the south, creating two homogeneous zones divided by the UN controlled buffer zone.",
  },
  {
    year: "1979",
    title: "The Nicosia Master Plan, 1979",
    description:
      "The Nicosia Master Plan was a daring collaborative initiative of Lellos Demetriades and Mustafa Akıncı with the aim to solve tangible and quotidian problems caused by unplanned development following the 1974 war and the division of the island. The project started in 1977 when the leaders of the two communities of Nicosia agreed on the need for a common sewerage system. In 1979, under the umbrella of the United Nations Development Programme (UNDP), the leaders agreed to work, together with their technical teams, on the improvement of the existing and future habitat and human settlement conditions of the citizens of Nicosia. A bicommunal multidisciplinary team of national and international experts such as town planners, architects, civil engineers, sociologists, economists, etc. was formed in 1981 to prepare a joint Master Plan which remains in force until today. ",
  },
  {
    year: "1983",
    title: "The unilateral proclamation of 1983",
    description:
      "After the intercommunal conflict of 1963-64 and the retreat of Turkish Cypriots into the enclaves, the Turkish Cypriot leadership explored new forms of governance. An administrative body was formed and operated under different titles reflecting the aftermath of the conflict, the intercommunal negotiation process of 1968-1974 and the political expectations of the community.  In 1983 the Turkish Cypriot leadership unilaterally declared the establishment of the ‘Turkish Republic of Northern Cyprus’ (15 November) which is until today only recognised by Turkey which continues to station approximately 35,000 troops in the island. After 1974, many Turkish Cypriots are said to have left their homes and workplaces in the centre of Nicosia while immigrants from Turkey with different socio-cultural backgrounds and generally lower income levels, settled (were placed) in the vacant places.",
  },
  {
    year: "1990",
    title: "The granting of work permits to third country nationals, 1990s",
    description:
      "Due to lack of workers, the Republic of Cyprus became in the beginning of 1990s from a country of immigrants, a host country for thousands of third country nationals when approval was given for granting work permits. Immigration to Cyprus increased in the second half of the 2000s, with the influx of European citizens, following Cyprus’s accession to the European Union in 2004.",
  },
  {
    year: "2003",
    title: "The opening of the crossings in 2003",
    description:
      "On 23 April 2003 travel restrictions were eased and people were allowed to cross the dividing line for the first time since 1974. This change prompted urban rehabilitation aided by UN and EU programmes and encouraged business activity within the old city. This was followed by the referendum on the reunification of the island based on the proposed United National Plan- known as the Annan Plan- a year later, at which the majority of the Greek Cypriot community voted against and the majority of the Turkish Cypriots in favour. The opening of the Ledra Palace crossing was followed by the opening of the Ledra Street crossing on 3 April 2008. Currently, there are nine open crossing points all over Cyprus.",
  },
  {
    year: "2004",
    title: "Cyprus’s accession to the European Union, 2004 ",
    description:
      "Despite the Cyprus question, on May 1, 2004, Cyprus became a full EU Member State, along with the other nine acceding countries. The accession of Cyprus into the Union inevitably had a significant impact on the economic development of the island, its demographic composition due to the influx of European citizens, and the government and administration of the country which had to adapt to EU laws and regulations. This event however, also created a further chasm across the divide, demographically, culturally, and financially, as the EU acquis is suspended in the northern part of the island.",
  },
  {
    year: "2013",
    title: "The economic crisis in 2013",
    description:
      "The events of March 2013 made Cyprus a headline on the news around the world when the Eurogroup and the recently inaugurated President of the Republic of Cyprus, Nicos Anastasiades, agreed to a rescue deal that would include the bail-in of uninsured and insured depositors in all Cypriot financial institutions. As experts claimed, the consequences were both direct and enduring. The haircut of deposits in the country’s two largest banks was unprecedented in conception and scale and dealt a huge blow to the Cypriot economy.",
  },
  {
    year: "2020",
    title: "The Covid-19 pandemic 2020 ",
    description:
      "On March 11, 2020, the World Health Organization declared COVID-19, the disease caused by the SARS-CoV-2, a pandemic, following a rising spread of a potentially lethal virus. The world was alarmed. Countries sealed borders, schools closed, people started wearing masks and “social distancing”. A few days earlier than the WHO’s announcement, the leaders of the two communities in Cyprus and the members of the Technical Committee on Health met to discuss how to deal with this unexpected eruption of the pandemic. Despite the fact that hitherto Cyprus had not Covid-19 cases, the Minister of Health announced the closing of four of the crossings. They remained closed for 15 months.",
  },
];

export default keyEvents;
