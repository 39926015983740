import React from "react";

import { BsFacebook, BsInstagram } from "react-icons/bs";

function VerticalNavBar() {
  return (
    <nav className="router-vertical-nav">
      <div>
        <div className="router-vertical-label" to="#">
          engage with us
        </div>
        <div className="router-vertical-subdiv">
          <a
            className="router-vertical-link"
            rel="noopener noreferrer"
            href="https://www.facebook.com/deepnicproject/"
            target="_blank"
          >
            <BsFacebook size={20} />
          </a>
          <a
            className="router-vertical-link"
            rel="noopener noreferrer"
            href="https://www.instagram.com/deep_nic_project/?hl=om-et"
            target="_blank"
          >
            <BsInstagram size={20} />
          </a>
        </div>
      </div>
    </nav>
  );
}

export default VerticalNavBar;
