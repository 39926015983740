import React, { useState, useRef } from "react";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {
  search,
  takePhotos,
} from "../../services/MonumentsActivityContext/monuments.context";

function LeftPaneHeader({
  setData,
  setPhotographer,
  setArchive,
  setCaption,
  setYear,
  setCurrentVideoIndex,
}) {
  const navigate = useNavigate();
  const searchRef = useRef();

  const [searchResults, setSearchResults] = useState();

  const handleSelection = (e) => {
    takePhotos(searchResults[e.target.id]).then((data) => {
      const parts = data.info.notes.split("\b");

      if (parts[1]) {
        const partsTwo = parts[1].split("\n");
        data.info["partTwo"] = partsTwo[0];
        data.info["partThree"] = partsTwo[1];
      }

      data.info["partOne"] = parts[0];

      setArchive(data.material[0].archive);
      setPhotographer(data.material[0].photographer);
      setCaption(data.material[0].caption);
      setYear(data.material[0].year);
      setData(data);
      setCurrentVideoIndex();
    });
    setSearchResults(null);
    searchRef.current.value = "";
  };

  return (
    <Container fluid>
      <Row style={{ background: "#697378" }}>
        <Col className="ms-5" xs="auto">
          <img
            className="selections-area-logo"
            onClick={() => {
              navigate("/");
            }}
            src="/deep_nic_yellow.png"
          />
        </Col>
        <Col className="ms-5 mt-4 mb-2">
          <div className="search-container">
            <InputGroup>
              <InputGroup.Text className="search-bar-icon">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </InputGroup.Text>
              <Form.Control
                size="sm"
                placeholder="Search"
                aria-label="Search"
                className="search-bar-input"
                ref={searchRef}
                onChange={(e) => {
                  if (e.target.value == "") {
                    setSearchResults(null);
                  } else {
                    search(e.target.value).then((data) => {
                      setSearchResults(data);
                    });
                  }
                }}
              />
            </InputGroup>
            {searchResults && searchRef.current.value != "" && (
              <div className="search-reccomendations">
                <ul>
                  {searchResults.map((s, index) => {
                    return (
                      <li key={index} id={index} onClick={handleSelection}>
                        {s.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {searchResults &&
              searchRef.current.value != "" &&
              searchResults.length == 0 && (
                <div className="search-reccomendations">
                  <ul>
                    <li>No results found</li>
                  </ul>
                </div>
              )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default LeftPaneHeader;
