import React from "react";
import axios from "axios";

export const buildingRequest = (year) => {
  let buildings = axios
    .post(`${process.env.REACT_APP_API_BASE_URL}/api/index.php`, {
      executeQuery: "monuments",
      apiKey: process.env.REACT_APP_API_KEY,
      dataObject: { year: year },
    })
    .then(function (response) {
      console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
  return buildings;
};

export const pointsOfInterestRequest = () => {
  let points = axios
    .post(`${process.env.REACT_APP_API_BASE_URL}/api/index.php`, {
      executeQuery: "pointsOfInterest",
      apiKey: process.env.REACT_APP_API_KEY,
    })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });

  return points;
};

export const search = async (keyword) => {
  const monumentsResults = await buildingRequest(2020);
  const pointsResults = await pointsOfInterestRequest();

  const monumentsResultsPassed = monumentsResults
    .filter((d) => d.name.toLowerCase().includes(keyword.toLowerCase()))
    .map((d) => ({ ...d, type: "monument" }));

  const pointsResultsPassed = pointsResults
    .filter((d) => d.name.toLowerCase().includes(keyword.toLowerCase()))
    .map((d) => ({ ...d, type: "pointOfInterest" }));

  const finalResults = [...monumentsResultsPassed, ...pointsResultsPassed];

  return finalResults;
};

export const takePhotos = async (object) => {
  if (object.type == "monument") {
    let material = axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/index.php`, {
        executeQuery: "materialMonument",
        apiKey: "dEePNIc2023",
        dataObject: { monumentID: object.id },
      })
      .then(function (response) {
        return {
          material: response.data.data,
          info: object,
          type: "MONUMENT BUILDING",
        };
      })
      .catch(function (error) {
        console.log(error);
      });
    return material;
  } else {
    let material = axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/index.php`, {
        executeQuery: "materialPointOfInterest",
        apiKey: "dEePNIc2023",
        dataObject: {
          pointOfInterestID: object.id,
        },
      })
      .then(function (response) {
        return {
          material: response.data.data,
          info: object,
          type: "POINT OF INTEREST",
        };
      })
      .catch(function (error) {
        console.log(error);
      });
    return material;
  }
};
