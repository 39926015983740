import React from "react";

import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import CensusRightPaneNationality from "./CensusRightPaneNationality";
import CensusRightPaneAges from "./CensusRightPaneAges";

function CensusRightPane({ dataForTheRightPane, setLoading, loading, open }) {
  const navigate = useNavigate();

  if (dataForTheRightPane == null) {
    return (
      <div className="business-totals-div">
        <div className="position-buttons-on-the-map-business">
          <Button
            onClick={() => {
              navigate("/business");
            }}
            className="business-activity-button-map"
          >
            Business Activity
          </Button>
          <Button
            onClick={() => {
              navigate("/monuments");
            }}
            className="monuments-button-map"
          >
            Monument Building
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div
      className={
        dataForTheRightPane.nationality != undefined
          ? "business-totals-div"
          : null
      }
    >
      {dataForTheRightPane.nationality != undefined ? (
        <CensusRightPaneNationality
          data={dataForTheRightPane}
          setLoading={setLoading}
          loading={loading}
        />
      ) : (
        <CensusRightPaneAges
          data={dataForTheRightPane}
          setLoading={setLoading}
          loading={loading}
          open={open}
        />
      )}
    </div>
  );
}

export default CensusRightPane;
