import React, { useState, useEffect, useRef } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import {
  censusRequestAge,
  formatAgeData,
} from "../../services/CensusActivityContext/census.context";

import CensusYearSelectAges from "./CensusYearSelectAges";
import CensusMultiRegionSelect from "./CensusMultiRegionSelect";
import CensusAgeNationalitySelect from "./CensusAgeNationalitySelect";

import SideNote from "./SideNote";

function CensusLeftPaneTabAgeGroups({ setLoading, setDataForTheRightPane }) {
  const [error, setError] = useState("");
  const [years, setYears] = useState([]);
  const [agesNationalities, setAgesNationalities] = useState([]);
  const [regions, setRegions] = useState([]);
  const [dataMap, setDataMap] = useState([]);

  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedAgeNationality, setSelectedAgeNationality] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState([]);

  const yearRef = useRef();
  const ageNationalityRef = useRef();
  const regionRef = useRef();

  useEffect(() => {
    censusRequestAge().then((data) => {
      let formatedData = formatAgeData(data);
      setYears(formatedData.years);
      setAgesNationalities(formatedData.ages);
      setRegions(formatedData.regions);
      setDataMap(data);
    });
  }, []);

  return (
    <>
      <Row xs="auto">
        <Col className="ms-0" xs="auto">
          <div className="business-search-title">Search</div>
          <div>
            <span className="fill-error">{error}</span>
          </div>
        </Col>
      </Row>
      <Row className="mb-2" xs="auto">
        <Col xs="auto">
          <CensusYearSelectAges
            arrayMap={years}
            title={"Year"}
            setSelectedYear={setSelectedYear}
            yearRef={yearRef}
          />
        </Col>
        <Col xs="auto">
          <CensusAgeNationalitySelect
            arrayMap={agesNationalities}
            title={"Age"}
            setSelectedAgeNationality={setSelectedAgeNationality}
            ageNationalityRef={ageNationalityRef}
          />
        </Col>
        <Col xs="auto">
          <CensusMultiRegionSelect
            title={"Region"}
            setSelectedRegion={setSelectedRegion}
            arrayMap={regions}
            regionRef={regionRef}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            className="apply-filters-button"
            size="lg"
            onClick={() => {
              let error = "";
              if (selectedRegion.length == 0) {
                error += "select region, ";
              }
              if (selectedAgeNationality == null) {
                error += "select age, ";
              }
              if (selectedYear == null) {
                error += "select year, ";
              }
              if (error !== "") {
                error += " and then proceed";
                setError(error);
                return;
              } else {
                setError("");

                let data = {
                  data: dataMap,
                  regions: selectedRegion,
                  ages: selectedAgeNationality,
                  year: selectedYear,
                };

                setLoading(true);
                setDataForTheRightPane(data);
                setError();
              }
            }}
          >
            APPLY FILTERS
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          {selectedYear && (
            <span className="selected-filters">{selectedYear}</span>
          )}
        </Col>
        <Col xs="auto">
          {selectedAgeNationality && (
            <span className="selected-filters">{selectedAgeNationality}</span>
          )}
        </Col>
        {selectedRegion &&
          !selectedRegion.includes("All") &&
          selectedRegion.map((region) => (
            <Col xs="auto">
              <span className="selected-filters">{region}</span>
            </Col>
          ))}
        {selectedRegion.includes("All") && (
          <Col xs="auto">
            <span className="selected-filters">All</span>
          </Col>
        )}
      </Row>
      <Row>
        <SideNote />
      </Row>
    </>
  );
}

export default CensusLeftPaneTabAgeGroups;
