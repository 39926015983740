import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Demography from "../../pdfs/Demography.pdf";

function SideNote() {
  return (
    <Col className="ms-3 mt-4" xs="auto">
      <Row
        style={{
          fontFamily: "Poppins, sans-serif",
          fontSize: 10,
          width: "80%",
        }}
      >
        Search results are based on the data that was available during our
        research. They are not extensive and may not reflect the total
        demographics of the urban centre of Nicosia. For more information on the
        methodology the project used and the limitations and difficulties we
        encountered please visit the
        <a
          href={Demography}
          download="Demography"
          target="_blank"
          rel="noreferrer"
          style={{
            padding: 0,
            color: "black",
            fontFamily: "Poppins, sans-serif",
            fontSize: 8,
          }}
        >
          {"demography overview"}
        </a>
      </Row>
    </Col>
  );
}

export default SideNote;
