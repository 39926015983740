import React from "react";
import FrontPageUpper from "../components/Core/FrontPageUpper";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import YourSVGComponent from "../components/Core/customSvg";
import FooterNavBar from "../components/NavBars/FooterNavBar";

function HomePage() {
  return (
    <>
      <FrontPageUpper
        picture={"homepage_image.jpg"}
        credits={""}
        secondCredit={""}
      />
      <Container fluid style={{ background: "#C7C9C9" }}>
        <Row>
          <Col>
            <img
              className="home-left-right"
              src="/home_left.jpg"
              alt="picture"
            />
            <img
              className="home-left-right"
              src="/home_center.jpg"
              alt="picture"
            />
            <img
              className="home-left-right"
              src="/home_right.jpg"
              alt="picture"
            />
          </Col>
        </Row>
        <Row className="justicy-content-center">
          <Col xs="auto" className="mt-5 mb-1 me-5 ms-5">
            <img className="home-logo" src="/deep_nic_purple_.png" />
          </Col>
          <Col className="m-5">
            <p className="home-paragraph">
              <span style={{ fontWeight: 500 }}>
                Deep Nic is a map based platform{" "}
              </span>
              where historical research is combined with geography and
              technology and allows, through data visualization , a
              multi-temporal and multi-dimensional exploration of the urban
              center of Nicosia form 1960 until 2020.
            </p>
            <div className="home-start-exploring">
              <span>Begin your exploration!</span>
              <a href="/selection">
                <YourSVGComponent
                  style={{
                    height: "auto",
                    position: "absolute",
                    bottom: "20%",
                    maxWidth: "25%",
                  }}
                  classNam={"home-explore-logo"}
                />
              </a>
            </div>
          </Col>
        </Row>
        <FooterNavBar />
      </Container>
    </>
  );
}

export default HomePage;
