import "./App.css";
import { RouterProvider } from "react-router-dom";
import router from "./router.js";

// import { BuildingsContextProvider } from "./services/BuildingsContext/buildings.context";

function App() {
  return (
    <div className="App">
      {/* <BuildingsContextProvider> */}
      <RouterProvider router={router} />
      {/* </BuildingsContextProvider> */}
    </div>
  );
}

export default App;
