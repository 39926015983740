import React from "react";

import Form from "react-bootstrap/Form";

function SelectSector({
  setDisableButton,
  title,
  isDisabled,
  arrayMap,
  setSelectedSector,
  sectorRef,
}) {
  return (
    <Form.Select
      ref={sectorRef}
      aria-label={title}
      className="business-select"
      disabled={isDisabled}
      onChange={(e) => {
        setSelectedSector(e.target.value);
        setDisableButton(false);
      }}
    >
      <option hidden>{title}</option>
      {arrayMap.map((sector, index) => (
        <option key={index} className="background-color-options" value={sector}>
          {sector}
        </option>
      ))}
    </Form.Select>
  );
}

export default SelectSector;
