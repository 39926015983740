import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LeftPaneHeader from "./LeftPaneHeader";

import { Carousel } from "react-responsive-carousel";
import ReactPlayer from "react-player";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function MonumentsLeftPane({ dataForTheLeftPane }) {
  const [data, setData] = useState();
  const [photographer, setPhotographer] = useState();
  const [archive, setArchive] = useState();
  const [year, setYear] = useState();
  const [caption, setCaption] = useState();
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  useEffect(() => {
    setData(dataForTheLeftPane);

    setCurrentVideoIndex();

    if (dataForTheLeftPane != undefined) {
      setArchive(dataForTheLeftPane.material[0].archive);
      setYear(dataForTheLeftPane.material[0].year);
      setPhotographer(dataForTheLeftPane.material[0].photographer);
      setCaption(dataForTheLeftPane.material[0].caption);

      const parts = dataForTheLeftPane.info.notes.split("/b");

      if (parts[1]) {
        const partsTwo = parts[1].split("/n");
        dataForTheLeftPane.info["partTwo"] = partsTwo[0];
        dataForTheLeftPane.info["partThree"] = partsTwo[1];
      }

      dataForTheLeftPane.info["partOne"] = parts[0];
    }
  }, [dataForTheLeftPane]);

  if (data == null) {
    return (
      <LeftPaneHeader
        setData={setData}
        setPhotographer={setPhotographer}
        setArchive={setArchive}
        setCaption={setCaption}
        setYear={setYear}
        setCurrentVideoIndex={setCurrentVideoIndex}
      />
    );
  }

  return (
    <div className="selections-area">
      <LeftPaneHeader
        setData={setData}
        setPhotographer={setPhotographer}
        setArchive={setArchive}
        setCaption={setCaption}
        setYear={setYear}
        setCurrentVideoIndex={setCurrentVideoIndex}
      />
      <Container fluid>
        <Row className="first-text">
          <Col className="ms-5 mt-5">
            <div>
              {data.type}
              {data.type != "MONUMENT BUILDING" && (
                <img
                  src="/point-icon_grey.svg"
                  style={{ width: 30, height: 30, marginLeft: 8 }}
                />
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            className={
              data.info.includes != undefined && data.info.includes != ""
                ? "ms-5 me-3 mt-0 second-text"
                : "ms-5 me-3 mb-4 mt-0 second-text"
            }
          >
            {data.type == "MONUMENT BUILDING" && data.number != null ? (
              <Col>
                {data.number < 10
                  ? `0${data.number} ${data.info.name}`
                  : `${data.number} ${data.info.name}`}
              </Col>
            ) : (
              <Col>{data.info.name}</Col>
            )}
          </Col>
        </Row>
        {data.info.includes != undefined && data.info.includes != "" && (
          <Row>
            <Col className="ms-5 mt-1 mb-4 mt-0 includes-text">
              {`(${data.info.includes})`}
            </Col>
          </Row>
        )}
        <Row>
          {data.material.length > 0 && (
            <Carousel
              showArrows={true}
              infiniteLoop={false}
              dynamicHeight={false}
              showThumbs={false}
              showIndicators={false}
              key={data.material.length}
              onChange={(index, item) => {
                setArchive(item.props.children.props.archive);
                setPhotographer(item.props.children.props.photographer);
                setCaption(item.props.children.props.caption);
                setYear(item.props.children.props.year);
                setCurrentVideoIndex(index);
              }}
            >
              {data.material.map((mat, index) => (
                <div key={index}>
                  {mat.type == "picture" ? (
                    <img
                      style={{ maxHeight: 500, maxWidth: 500 }}
                      src={`${process.env.REACT_APP_API_BASE_URL}/material/${mat.url}`}
                      photographer={mat.photographer}
                      archive={mat.archive}
                      caption={mat.caption}
                      year={mat.year}
                    />
                  ) : (
                    <ReactPlayer
                      url={`${process.env.REACT_APP_API_BASE_URL}/material/videos/${mat.url}`}
                      controls
                      photographer={mat.photographer}
                      archive={mat.archive}
                      year={mat.year}
                      caption={mat.caption}
                      playing={index === currentVideoIndex}
                    />
                  )}
                </div>
              ))}
            </Carousel>
          )}
          {data.material.length > 0 && (
            <div className="archive-photographer ms-5 mt-4">
              {photographer != "-" && (
                <div>{`Photographer: ${photographer}`}</div>
              )}
              {archive != "-" && <div>{`Archive: ${archive}`}</div>}
              {caption != "-" && <div>{`${caption}`}</div>}
              {year != 0 && <div>{`Captured in: ${year}`}</div>}
            </div>
          )}
        </Row>
        <Row>
          <Col className="ms-5 mt-3 me-0 mb-4">
            {data.info.partTwo == undefined && (
              <p className="third-paragraph">{data.info.notes}</p>
            )}
            {data.info.partTwo != undefined && (
              <p className="third-paragraph">
                {data.info.partOne}
                <br></br>
                <br></br>
                <b>{data.info.partTwo}</b>
                {data.info.partThree}
              </p>
            )}
            {data.info.date_created && (
              <div className="third-paragraph">
                <b>Date of establishment:</b> {data.info.date_created}
              </div>
            )}
            {data.info.creator && data.info.creator != "-" && (
              <div className="third-paragraph">
                <b>Creator:</b> {data.info.creator}
              </div>
            )}
            {data.info.reg_name && (
              <div className="third-paragraph">
                <b>Region:</b> {data.info.reg_name}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MonumentsLeftPane;
