import React from "react";
import { Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { renderToString } from "react-dom/server";
import axios from "axios";

function MonumentsMarker({ monument, index, setDataForTheLeftPane, setOpen }) {
  const monumentsIcon = new L.divIcon({
    className: "custom-marker-pink",
    iconSize: [24, 24],
    html: renderToString(index + 1),
  });

  const map = useMap();

  return (
    <Marker
      key={index}
      position={[monument.lat, monument.lon]}
      icon={monumentsIcon}
      eventHandlers={{
        click: () => {
          axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/api/index.php`, {
              executeQuery: "materialMonument",
              apiKey: "dEePNIc2023",
              dataObject: { monumentID: monument.id },
            })
            .then(function (response) {
              let data = {
                material: response.data.data,
                info: monument,
                type: "MONUMENT BUILDING",
                number: index + 1,
              };

              setDataForTheLeftPane(data);
              setOpen(true);
              map.setView(
                [parseFloat(monument.lat), parseFloat(monument.lon) + 0.01],
                15
              );
            })
            .catch(function (error) {
              console.log(error);
            });
        },
        mouseover: (event) => event.target.openPopup(),
        mouseout: (event) => event.target.closePopup(),
      }}
    >
      <Popup>
        <div className="popup-content-custom">
          <img
            className="popup-image"
            src={`${process.env.REACT_APP_API_BASE_URL}/material/${monument.frontImage}`}
          />
          <div className="popup-custom-title">
            {monument.name.length >= 40
              ? monument.name.substring(0, 36) + "..."
              : monument.name}
          </div>
        </div>
      </Popup>
    </Marker>
  );
}

export default MonumentsMarker;
