import React from "react";

import Form from "react-bootstrap/Form";

function CensusYearSelectNationality({
  title,
  yearRef,
  arrayMap,
  setSelectedYear,
  regionRef,
  setDisableButton,
  setSelectedAgeNationality,
  setSelectedRegion,
  setSelectedRegionName,
}) {
  return (
    <Form.Select
      aria-label={title}
      className="census-select"
      ref={yearRef}
      onChange={(e) => {
        setSelectedYear(e.target.value);
        if (regionRef.current != undefined) {
          regionRef.current.disabled = false;
        }
        setDisableButton(false);
        setSelectedRegion();
        setSelectedAgeNationality();
        setSelectedRegionName();
      }}
    >
      <option hidden>{title}</option>
      {arrayMap.map((year, index) => (
        <option key={index} className="background-color-options" value={year}>
          {year}
        </option>
      ))}
    </Form.Select>
  );
}

export default CensusYearSelectNationality;
