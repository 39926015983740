import React from "react";
import FrontPageUpper from "../components/Core/FrontPageUpper";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FooterNavBar from "../components/NavBars/FooterNavBar";

function AboutUs() {
  return (
    <>
      <FrontPageUpper
        picture={"aboutus_image.jpg"}
        credits={"Credits: Saray Square, Nicosia, 1990"}
        secondCredit={
          "To Mati 53 © The Leventis Municipal Museum of Nicosia - Photographic Archive, Cyprus"
        }
      />
      <Container fluid style={{ background: "#C7C9C9" }}>
        <Row>
          <Col className="ms-5 mt-1 mb-2 me-5">
            <p className="about-us-about-text">ABOUT</p>
            <p className="about-us-about-paragraph">
              DeepNic aims to investigate the evolution and transformation of
              Nicosia’s urban centre from a historical and sociological
              perspective using several types of data such as written, oral, and
              visual. It takes the whole of the urban centre of Nicosia as a
              case study, to examine how its transformation within a certain
              period is affected by key political and social developments that
              have occurred from 1960 until today, namely, the proclamation of
              the independence, the 1963-64 intercommunal strife, the 1974 war
              and the division of the island; the beginning of the Master Plan
              in 1979; the unilateral proclamation in 1983; first mass granting
              of employment permissions to immigrants in the 1990s; the opening
              of the crossings in 2003; the financial crisis in 2013; and the
              outbreak of the Covid-19 pandemic in 2020.
            </p>
            <div className="about-us-objectives-div">
              <p className="about-us-objectives-par">
                Which are the objectives of the project?
              </p>
            </div>
            <div className="about-us-bullets-div">
              <ul>
                <li className="about-us-bullet-points">
                  Collect historical information related to specific aspects of
                  the urban centre, i.e. demographics, business activity,
                  monument building, as they were formed following specific
                  political events.
                </li>
                <li className="about-us-bullet-points">
                  Bring to the surface multi-temporal perspectives of Nicosia
                  that would encourage the emergence and discussion of
                  marginalised or, in some cases, conflicting historical
                  narratives.
                </li>
                <li className="about-us-bullet-points">
                  Facilitate history education across the divide.
                </li>
              </ul>
            </div>
          </Col>

          <Col className="ms-5 mt-1 mb-2 me-5">
            <div className="about-us-purple-div">
              <div className="about-us-yellow-div">
                <p className="about-us-yellow-div-text">
                  EXPLORE <br /> the evolution and transformation of NICOSIA’S
                  URBAN CENTRE
                </p>
              </div>
            </div>
            <div className="about-us-project-div">
              <p>
                In this project, historical research is combined with geography
                and techology in a data visualization effort that allows a
                multi-temporal and multi-dimensional historical narrative.
              </p>
            </div>
            <div className="partners-supporters-div">
              <p className="partners">
                PROJECT PARTNERS:
                <br></br>
                <span style={{ color: "black", fontWeight: 400 }}>
                  <a
                    style={{
                      color: "black",
                      fontWeight: 400,
                      textDecoration: "none",
                    }}
                    href="https://www.cyens.org.cy/en-gb/"
                    target="_blank"
                  >
                    CYENS CENTRE OF EXCELLENCE (Lead)
                  </a>
                  ,{" "}
                  <a
                    style={{
                      color: "black",
                      fontWeight: 400,
                      textDecoration: "none",
                    }}
                    href="https://www.ahdr.info/"
                    target="_blank"
                  >
                    Association for Historical Dialogue and Research
                  </a>
                  ,{" "}
                  <a
                    style={{
                      color: "black",
                      fontWeight: 400,
                      textDecoration: "none",
                    }}
                    href="https://www.appios.org/"
                    target="_blank"
                  >
                    Appios Communications
                  </a>
                  .
                </span>
              </p>
              <p className="partners">
                SUPPORTERS:
                <br></br>
                <span style={{ color: "black", fontWeight: 400 }}>
                  <a
                    style={{
                      color: "black",
                      fontWeight: 400,
                      textDecoration: "none",
                    }}
                    target="_blank"
                    href="https://www.pio.gov.cy/en/"
                  >
                    Press and Information Office
                  </a>
                  ,{" "}
                  <a
                    style={{
                      color: "black",
                      fontWeight: 400,
                      textDecoration: "none",
                    }}
                    target="_blank"
                    href="https://www.digital-herodotus.eu/"
                  >
                    Digital Herodotus
                  </a>
                  ,{" "}
                  <a
                    style={{
                      color: "black",
                      fontWeight: 400,
                      textDecoration: "none",
                    }}
                    target="_blank"
                    href="https://www.leventisfoundation.org/leventis-municipal-museum"
                  >
                    The Leventis Municipal Museum of Nicosia
                  </a>
                  ,{" "}
                  <a
                    style={{
                      color: "black",
                      fontWeight: 400,
                      textDecoration: "none",
                    }}
                    target="_blank"
                    href="https://www.ouc.ac.cy/index.php/el/"
                  >
                    Open University of Cyprus
                  </a>
                  ,{" "}
                  <a
                    style={{
                      color: "black",
                      fontWeight: 400,
                      textDecoration: "none",
                    }}
                    target="_blank"
                    href="https://www.boccf.org/"
                  >
                    Bank of Cyprus Cultural Foundation
                  </a>
                </span>
              </p>
            </div>
          </Col>
        </Row>
        <FooterNavBar />
      </Container>
    </>
  );
}

export default AboutUs;
