import React from "react";
import { Container } from "react-bootstrap";

function Disclaimer() {
  return (
    <Container fluid style={{ background: "#C7C9C9", height: "100vh" }}>
      <h1 className="disclaimer-title">Disclaimer</h1>
      <div className="disclaimer">
        CYENS Centre of Excellence is a research and innovation centre that
        operates under the motto “Inspired by Humans Designed for Humans”. Its
        vision is to produce world-class research that drives innovation towards
        a social and economic benefit, while conducting excellent,
        internationally competitive scientific research in the areas of visual
        sciences, human factors and design, communication, and artificial
        intelligence. It is supported by the European Commission, the Republic
        of Cyprus, and its founding partners: the Municipality of Nicosia, the
        Max Planck Institute for Informatics (MPI), University College London,
        the University of Cyprus, the Cyprus University of Technology, and the
        Open University of Cyprus. DeepNic is a research project, hosted by
        CYENS and funded by the Research Promotion Foundation. In conjunction
        with key socio-political events that occurred between 1960 and 2020, it
        investigates the evolution and transformation of Nicosia’s urban centre
        in three thematic areas: demography, business activity and monument
        building. DeepNic’s research team includes both Greek Cypriots and
        Turkish Cypriots and primary research was conducted across the Green
        Line. The project acknowledges the suffering that the people of the
        island have experienced during the island’s recent history and that
        terminology related to the conflicts between the Greek and Turkish
        Cypriot communities can be politically charged. CYENS aims to be
        objective and to use scientific inquiry as a tool that transcends
        political and social differences. Taking all this into consideration,
        CYENS’ decision for any publications derived from the DeepNic project is
        to use language that is as inclusive and respectful towards as many
        sensibilities as possible, with multiperspectivity and critical thinking
        as guiding principles, and to present DeepNic’s research results
        objectively, without any intention to offend or alienate. With this
        project, CYENS does not, in any way, afford any entities recognition
        that has not been granted by law.
      </div>
    </Container>
  );
}

export default Disclaimer;
