import React from "react";

import { motion } from "framer-motion";

import { useNavigate } from "react-router-dom";

import SelectionButtons from "../components/NavBars/SelectionButtons";
import YourSVGComponentTwo from "../components/NavBars/customSvg2";

function Selection() {
  const navigate = useNavigate();

  return (
    <div className="selection-div">
      <YourSVGComponentTwo navigate={navigate} />
      <SelectionButtons />
    </div>
  );
}

export default Selection;
