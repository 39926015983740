import React, { useState, useEffect } from "react";

import Spinner from "react-bootstrap/Spinner";

import {
  getColor,
  getPositions,
  filterEstablishedData,
} from "../../services/BusinessActivityContext/business.context";

import { MapContainer, Polyline, Popup, Tooltip } from "react-leaflet";
import { TileLayer } from "react-leaflet/TileLayer";
import "leaflet/dist/leaflet.css";

import Legend from "./Legend";

import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";

const {
  REACT_APP_MAPBOX_USERNAME,
  REACT_APP_MAPBOX_TILE_ID,
  REACT_APP_MAPBOX_API_KEY,
} = process.env;

function BusinessActivityEstablishments({ data, setLoading, loading, open }) {
  const [correctData, setCorrectData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setCorrectData(filterEstablishedData(data));
    setLoading(false);
  }, [data]);

  return (
    <>
      <div
        className="position-buttons-on-the-map-business"
        style={{ width: !open ? "100%" : "50%" }}
      >
        <Button
          onClick={() => {
            navigate("/census");
          }}
          className="population-census-button-map"
        >
          Demography
        </Button>
        <Button
          onClick={() => {
            navigate("/monuments");
          }}
          className="monuments-button-map"
        >
          Monument Building
        </Button>
      </div>
      {loading && <Spinner animation="border" style={{ color: "#00FF97" }} />}
      {!loading && open && (
        <MapContainer
          style={{ width: "100%", height: "100vh" }}
          center={[35.1794996436, 33.3803205649]}
          zoom={13}
          scrollWheelZoom={true}
        >
          <TileLayer
            // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            // url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
            url={`https://api.mapbox.com/styles/v1/${REACT_APP_MAPBOX_USERNAME}/${REACT_APP_MAPBOX_TILE_ID}/tiles/256/{z}/{x}/{y}@2x?access_token=${REACT_APP_MAPBOX_API_KEY}`}
            attribution='&copy; <a href="https://www.mapbox.com/">Mapbox</a> contributors'
          />
          {correctData.map((region) => (
            <Polyline
              key={region.name}
              pathOptions={{
                color: getColor(region.number),
                fill: true,
                fillColor: getColor(region.number),
                weight: 1,
              }}
              positions={getPositions(region.outline)}
              eventHandlers={{
                mouseover: (event) => event.target.openPopup(),
                mouseout: (event) => event.target.closePopup(),
                onClick: (event) => event.target.openPopup(),
              }}
            >
              <Tooltip
                eventHandlers={{
                  mouseover: (event) => event.preventDefault(),
                  mouseout: (event) => event.preventDefault(),
                  onClick: (event) => event.preventDefault(),
                }}
              >
                <b>Name: </b>
                {region.name}
                <br></br>
                <b>Active establishments: </b>
                {region.number}
                <br></br>
                <b>Sector: </b>
                {region.sector}
              </Tooltip>
            </Polyline>
          ))}
          <Legend />
        </MapContainer>
      )}
      {!loading && !open && (
        <MapContainer
          style={{ width: "100%", height: "100vh" }}
          center={[35.1794996436, 33.3803205649]}
          zoom={13}
          scrollWheelZoom={true}
        >
          <TileLayer
            url={`https://api.mapbox.com/styles/v1/${REACT_APP_MAPBOX_USERNAME}/${REACT_APP_MAPBOX_TILE_ID}/tiles/256/{z}/{x}/{y}@2x?access_token=${REACT_APP_MAPBOX_API_KEY}`}
            attribution='&copy; <a href="https://www.mapbox.com/">Mapbox</a> contributors'
          />
          {correctData.map((region) => (
            <Polyline
              key={region.name}
              pathOptions={{
                color: getColor(region.number),
                fill: true,
                fillColor: getColor(region.number),
                weight: 1,
              }}
              positions={getPositions(region.outline)}
              eventHandlers={{
                mouseover: (event) => event.target.openPopup(),
                mouseout: (event) => event.target.closePopup(),
              }}
            >
              <Tooltip
                eventHandlers={{
                  mouseover: (event) => event.preventDefault(),
                  mouseout: (event) => event.preventDefault(),
                  onClick: (event) => event.preventDefault(),
                }}
              >
                <b>Name: </b>
                {region.name}
                <br></br>
                <b>Active establishments: </b>
                {region.number}
                <br></br>
                <b>Sector: </b>
                {region.sector}
              </Tooltip>
            </Polyline>
          ))}
          <Legend />
        </MapContainer>
      )}
    </>
  );
}

export default BusinessActivityEstablishments;
