import React, { useState } from "react";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { MdKeyboardArrowDown } from "react-icons/md";

export default function CensusMultiRegionSelect({
  arrayMap,
  regionRef,
  setSelectedRegion,
}) {
  const [personName, setPersonName] = useState([]);

  const handleChange = (event) => {
    setPersonName(event.target.value);
    setSelectedRegion(event.target.value);
  };

  const CustomIcon = () => {
    return <MdKeyboardArrowDown size={32} />;
  };

  return (
    <div>
      <Select
        multiple
        displayEmpty
        IconComponent={CustomIcon}
        ref={regionRef}
        size="small"
        style={{
          borderRadius: 18,
          width: 140,
          background: "#FF4545",
          borderColor: "#FF4545",
          marginBottom: 8,
          color: "white",
          fontFamily: "Poppins, sans-serif",
        }}
        value={personName}
        onChange={handleChange}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>Region</em>;
          }

          return selected.join(", ");
        }}
      >
        <MenuItem disabled value="">
          <em style={{ fontFamily: "Poppins, sans-serif" }}>Regions</em>
        </MenuItem>
        <MenuItem value="All">All</MenuItem>
        {arrayMap.map((name, index) => (
          <MenuItem value={name}>{name}</MenuItem>
        ))}
      </Select>
    </div>
  );
}
