import React from "react";

import Form from "react-bootstrap/Form";

function CensusRegionSelect({
  title,
  regionRef,
  arrayMap,
  setSelectedRegion,
  disabled,
  setSelectedRegionName,
}) {
  return (
    <Form.Select
      aria-label={title}
      disabled={disabled || false}
      className="census-select"
      ref={regionRef}
      onChange={(e) => {
        setSelectedRegion(e.target.value);
        setSelectedRegionName(e.target.options[e.target.selectedIndex].text);
      }}
    >
      <option hidden>{title}</option>
      {arrayMap.map((region, index) => (
        <option
          key={index}
          className="background-color-options"
          value={region.id}
        >
          {region.name}
        </option>
      ))}
    </Form.Select>
  );
}

export default CensusRegionSelect;
