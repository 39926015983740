import { motion } from "framer-motion";
import "./svgStyle2.css";

const YourSVGComponentTwo = ({ navigate }) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 50"
      className="selection-logo"
      onClick={() => {
        navigate("/");
      }}
    >
      <g>
        <polygon
          class="cls-2"
          points="48.89 30.09 49.49 30.09 49.38 26.57 49.2 26.57 47.67 28.71 46.13 26.57 45.95 26.57 45.84 30.09 46.42 30.09 46.49 28.01 47.6 29.51 47.69 29.51 48.83 28.01 48.89 30.09"
        />
        <path
          class="cls-2"
          d="m51.05,28.78c-.58,0-1.03.2-1.03.71,0,.4.3.66.8.66.33,0,.58-.12.76-.25.09.2.27.25.45.25.15,0,.32-.03.43-.09l-.03-.35c-.23.08-.31-.05-.31-.3v-.8c0-.69-.5-.86-.98-.86-.4,0-.7.12-.92.25l.06.46c.21-.13.45-.24.75-.24.36,0,.49.15.49.42v.2c-.14-.04-.29-.06-.47-.06Zm.47.74c-.14.1-.32.18-.51.18-.21,0-.37-.09-.37-.24,0-.17.19-.28.5-.28.12,0,.24.02.38.05v.3Z"
        />
        <path
          class="cls-2"
          d="m53.46,28.05l-.07-.25h-.49v3.45h.6v-1.23c.15.07.33.12.55.12.67,0,1.15-.42,1.15-1.25,0-.71-.34-1.15-.97-1.15-.35,0-.59.14-.77.31Zm1.14.89c0,.44-.21.7-.65.7-.17,0-.32-.04-.45-.09v-1.06c.15-.13.33-.22.55-.22.38,0,.54.28.54.66Z"
        />
        <path
          class="cls-2"
          d="m56.34,30.02c.15.07.33.12.55.12.67,0,1.15-.42,1.15-1.25,0-.71-.34-1.15-.97-1.15-.35,0-.59.14-.77.31l-.07-.25h-.49v3.45h.6v-1.23Zm0-1.53c.15-.13.33-.22.55-.22.38,0,.54.28.54.66,0,.44-.21.7-.65.7-.17,0-.32-.04-.45-.09v-1.06Z"
        />
        <path
          class="cls-2"
          d="m59.34,29.72c-.14,0-.2-.06-.2-.27v-1.66h-.6v1.76c0,.47.21.58.53.58.21,0,.33-.05.41-.09l-.03-.35s-.08.02-.11.02Z"
        />
        <path
          class="cls-2"
          d="m58.84,27.28c.21,0,.36-.16.36-.37s-.15-.37-.36-.37-.36.15-.36.37.15.37.36.37Z"
        />
        <path
          class="cls-2"
          d="m62.32,29.72c-.14,0-.19-.06-.19-.28v-.77c0-.62-.28-.93-.83-.93-.32,0-.57.12-.78.3l-.02-.23h-.57v2.28h.6v-1.59c.16-.14.36-.22.56-.22.3,0,.44.17.44.54v.74c0,.46.21.58.53.58.2,0,.33-.05.41-.09l-.03-.35s-.08.02-.12.02Z"
        />
        <path
          class="cls-2"
          d="m62.71,30.72c0,.35.31.59,1.06.59.81,0,1.34-.3,1.34-.82,0-.37-.27-.64-.92-.64h-.46c-.23,0-.34-.07-.34-.19,0-.08.05-.14.14-.2.1.02.2.03.31.03.63,0,1.02-.33,1.02-.88,0-.21-.06-.38-.16-.52l.4.02v-.37h-1.13s-.08-.01-.13-.01c-.63,0-1.05.34-1.05.88,0,.33.15.59.42.74-.16.11-.33.29-.33.53,0,.13.05.24.14.31-.19.12-.31.3-.31.52Zm1.11-2.57c.29,0,.45.18.45.47s-.16.48-.44.48-.45-.19-.45-.49.17-.46.44-.46Zm-.45,2.16s.06,0,.1,0h.63c.31,0,.48.1.48.25,0,.2-.24.33-.69.33s-.67-.11-.67-.31c0-.09.04-.19.15-.28Z"
        />
        <polygon
          class="cls-2"
          points="67.37 27.8 69.45 30.09 69.71 30.09 69.71 26.58 69.1 26.58 69.1 28.86 67.03 26.58 66.76 26.58 66.76 30.09 67.37 30.09 67.37 27.8"
        />
        <path
          class="cls-2"
          d="m71.04,29.46v-1.66h-.6v1.76c0,.47.21.58.53.58.21,0,.33-.05.41-.09l-.03-.35s-.08.02-.11.02c-.14,0-.2-.06-.2-.27Z"
        />
        <path
          class="cls-2"
          d="m70.74,27.28c.21,0,.36-.16.36-.37s-.15-.37-.36-.37-.36.15-.36.37.15.37.36.37Z"
        />
        <path
          class="cls-2"
          d="m72.84,30.14c.41,0,.77-.16.92-.3l-.14-.41c-.18.13-.4.22-.65.22-.44,0-.7-.27-.7-.76,0-.39.18-.67.5-.67.3,0,.4.23.42.42l.53-.15c-.06-.49-.38-.74-.91-.74-.67,0-1.15.43-1.15,1.2,0,.68.37,1.2,1.17,1.2Z"
        />
        <path
          class="cls-2"
          d="m75.26,27.74c-.72,0-1.19.45-1.19,1.2s.45,1.2,1.17,1.2,1.18-.48,1.18-1.22-.45-1.18-1.16-1.18Zm-.02,1.95c-.34,0-.56-.29-.56-.75s.22-.73.57-.73.56.27.56.73-.22.75-.57.75Z"
        />
        <path
          class="cls-2"
          d="m77.43,28.38c0-.15.17-.24.37-.23.19.01.42.1.45.36l.5-.13c-.04-.41-.41-.65-.91-.65s-.96.22-.96.72c0,.81,1.33.65,1.33,1.04,0,.14-.17.24-.42.24-.36,0-.51-.2-.56-.35l-.47.15c.11.36.41.62,1,.62.63,0,1.01-.28,1.01-.72,0-.79-1.34-.66-1.34-1.05Z"
        />
        <path
          class="cls-2"
          d="m79.53,27.28c.21,0,.36-.16.36-.37s-.15-.37-.36-.37-.36.15-.36.37.15.37.36.37Z"
        />
        <path
          class="cls-2"
          d="m80.03,29.72c-.14,0-.2-.06-.2-.27v-1.66h-.6v1.76c0,.47.21.58.53.58.21,0,.33-.05.41-.09l-.03-.35s-.08.02-.11.02Z"
        />
        <path
          class="cls-2"
          d="m82.56,29.4v-.8c0-.69-.5-.86-.98-.86-.4,0-.7.12-.92.25l.06.46c.21-.13.45-.24.75-.24.36,0,.49.15.49.42v.2c-.14-.04-.29-.06-.47-.06-.58,0-1.03.2-1.03.71,0,.4.3.66.8.66.33,0,.58-.12.76-.25.09.2.27.25.45.25.15,0,.32-.03.43-.09l-.03-.35c-.23.08-.31-.05-.31-.3Zm-.6.12c-.14.1-.32.18-.51.18-.21,0-.37-.09-.37-.24,0-.17.19-.28.5-.28.12,0,.24.02.38.05v.3Z"
        />
        <path
          class="cls-2"
          d="m83.41,26.48c-.21,0-.37.15-.37.37,0,.16.1.28.23.31-.03.14-.16.43-.26.56l.25.18c.15-.16.51-.59.51-1.01,0-.26-.16-.41-.37-.41Z"
        />
        <path
          class="cls-2"
          d="m84.67,28.38c0-.15.17-.24.37-.23.19.01.42.1.45.36l.5-.13c-.04-.41-.41-.65-.91-.65s-.96.22-.96.72c0,.81,1.33.65,1.33,1.04,0,.14-.17.24-.42.24-.36,0-.51-.2-.56-.35l-.47.15c.11.36.41.62,1,.62.63,0,1.01-.28,1.01-.72,0-.79-1.34-.66-1.34-1.05Z"
        />
        <path
          class="cls-2"
          d="m45.77,32.87v1.41c0,.62.28.93.81.93.33,0,.56-.12.74-.28.07.22.25.28.48.28.2,0,.32-.05.41-.09l-.03-.35c-.22.06-.31,0-.31-.24v-1.66h-.6v1.62c-.12.1-.29.19-.49.19-.29,0-.42-.16-.42-.54v-1.27h-.6Z"
        />
        <path
          class="cls-2"
          d="m49.26,33.64c.16-.16.36-.25.53-.25.11,0,.26.03.33.07h.03s.01-.63.01-.63c-.06-.02-.12-.03-.2-.03-.29,0-.53.15-.71.35l-.02-.29h-.58v2.28h.6v-1.51Z"
        />
        <path
          class="cls-2"
          d="m51.93,32.8c-.32,0-.56.12-.73.28v-1.64h-.6v3.71h.48l.04-.11c.16.1.37.17.64.17.66,0,1.15-.42,1.15-1.25,0-.71-.35-1.15-.97-1.15Zm-.29,1.9c-.17,0-.32-.04-.44-.09v-1.06c.15-.13.33-.22.55-.22.39,0,.54.28.54.66,0,.44-.21.7-.65.7Z"
        />
        <path
          class="cls-2"
          d="m55.37,34.47v-.8c0-.69-.5-.86-.98-.86-.4,0-.7.12-.92.25l.06.46c.21-.13.45-.24.75-.24.36,0,.49.15.49.42v.2c-.14-.04-.29-.06-.47-.06-.58,0-1.03.2-1.03.71,0,.4.3.66.8.66.33,0,.58-.12.76-.25.09.2.27.25.45.25.15,0,.32-.03.43-.09l-.03-.35c-.23.08-.31-.05-.31-.3Zm-.6.12c-.14.1-.32.18-.51.18-.21,0-.37-.09-.37-.24,0-.17.19-.28.5-.28.12,0,.24.02.38.05v.3Z"
        />
        <path
          class="cls-2"
          d="m58.55,34.79c-.14,0-.19-.06-.19-.28v-.77c0-.62-.28-.93-.83-.93-.32,0-.57.12-.78.3l-.02-.23h-.57v2.28h.6v-1.59c.16-.14.36-.22.56-.22.3,0,.44.17.44.54v.74c0,.46.21.58.53.58.2,0,.33-.05.41-.09l-.03-.35s-.08.02-.12.02Z"
        />
        <path
          class="cls-2"
          d="m61.46,34.71c-.44,0-.7-.27-.7-.76,0-.39.18-.67.5-.67.3,0,.4.23.42.42l.53-.15c-.06-.49-.38-.74-.91-.74-.67,0-1.15.43-1.15,1.2,0,.68.37,1.2,1.17,1.2.41,0,.77-.16.92-.3l-.14-.41c-.18.13-.4.22-.65.22Z"
        />
        <path
          class="cls-2"
          d="m64.66,34.95l-.11-.38c-.16.1-.37.18-.65.18-.42,0-.68-.21-.73-.65h1.5c.14-.74-.22-1.31-.96-1.31-.7,0-1.14.48-1.14,1.22,0,.68.38,1.19,1.21,1.19.43,0,.73-.14.89-.26Zm-.94-1.76c.31,0,.48.22.47.52h-1c.06-.34.26-.52.54-.52Z"
        />
        <path
          class="cls-2"
          d="m66.85,33.88v.74c0,.46.21.58.53.58.2,0,.33-.05.41-.09l-.03-.35s-.08.02-.12.02c-.14,0-.19-.06-.19-.28v-.77c0-.62-.28-.93-.83-.93-.32,0-.57.12-.78.3l-.02-.23h-.57v2.28h.6v-1.59c.16-.14.36-.22.56-.22.3,0,.44.17.44.54Z"
        />
        <path
          class="cls-2"
          d="m69.38,34.72c-.33,0-.42-.21-.42-.52v-.88h.79v-.46h-.79v-.66l-.6.06v.6h-.36v.46h.36v1.03c0,.59.31.85.81.85.2,0,.49-.04.68-.18l-.04-.43c-.12.07-.29.12-.42.12Z"
        />
        <path
          class="cls-2"
          d="m70.95,33.64c.16-.16.36-.25.53-.25.11,0,.26.03.33.07h.03s.01-.63.01-.63c-.06-.02-.12-.03-.2-.03-.29,0-.53.15-.71.35l-.02-.29h-.58v2.28h.6v-1.51Z"
        />
        <path
          class="cls-2"
          d="m74.04,34.58c-.16.1-.37.18-.65.18-.42,0-.68-.21-.73-.65h1.5c.14-.74-.22-1.31-.96-1.31-.7,0-1.14.48-1.14,1.22,0,.68.38,1.19,1.21,1.19.43,0,.73-.14.89-.26l-.11-.38Zm-.83-1.38c.31,0,.48.22.47.52h-1c.06-.34.26-.52.54-.52Z"
        />
        <path
          class="cls-2"
          d="m47.37,38.61h-.74c-.03.49-.41.7-.87.7v.54h.87v2.7h-.87v.7h2.48v-.7h-.87v-3.94Z"
        />
        <path
          class="cls-2"
          d="m50.37,38.52c-1.01,0-1.26.86-1.26,1.47,0,.82.31,1.47,1.19,1.47.35,0,.57-.18.62-.24v.74c0,.38-.09.72-.55.72-.5,0-.54-.38-.54-.67v-.04h-.68v.07c0,.74.3,1.3,1.22,1.3s1.25-.63,1.25-1.38v-1.96c0-.61-.24-1.47-1.26-1.47Zm0,2.26c-.34,0-.55-.2-.55-.79s.21-.79.55-.79.55.2.55.79-.21.79-.55.79Z"
        />
        <path
          class="cls-2"
          d="m54,40.4c-.35,0-.57.18-.62.24v-.74c0-.39.11-.72.56-.72.5,0,.53.38.53.67v.04h.68v-.07c0-.74-.29-1.3-1.2-1.3s-1.26.63-1.26,1.38v1.96c0,.68.24,1.47,1.26,1.47s1.26-.8,1.26-1.47c0-.82-.31-1.47-1.19-1.47Zm-.07,2.26c-.34,0-.55-.2-.55-.79s.21-.79.55-.79.55.2.55.79-.21.79-.55.79Z"
        />
        <path
          class="cls-2"
          d="m57.36,38.52c-.84,0-1.24.57-1.24,1.37v2.08c0,.8.41,1.37,1.24,1.37s1.24-.57,1.24-1.37v-2.08c0-.8-.41-1.37-1.24-1.37Zm-.56,1.37c0-.2.01-.7.56-.7.41,0,.55.28.55.5l-1.11,1.44v-1.24Zm1.12,2.08c0,.2-.01.7-.56.7-.41,0-.55-.28-.55-.5l1.11-1.43v1.24Z"
        />
        <rect class="cls-2" x="59.56" y="40.58" width="2.57" height=".7" />
        <path
          class="cls-2"
          d="m64.81,41.2c.53-.53.75-.89.75-1.38,0-.82-.42-1.3-1.24-1.3-.86,0-1.24.57-1.24,1.37v.27h.69v-.27c0-.2,0-.68.55-.68.49,0,.54.36.54.61,0,.37-.33.7-.55.92-.62.62-1.25,1.09-1.25,2.04v.47h2.5v-1.29h-.66v.59h-1.11c0-.44.54-.86,1.03-1.35Z"
        />
        <path
          class="cls-2"
          d="m67.8,38.52c-.84,0-1.24.57-1.24,1.37v2.08c0,.8.41,1.37,1.24,1.37s1.24-.57,1.24-1.37v-2.08c0-.8-.41-1.37-1.24-1.37Zm-.56,1.37c0-.2.01-.7.56-.7.41,0,.55.28.55.5l-1.11,1.44v-1.24Zm1.12,2.08c0,.2-.01.7-.56.7-.41,0-.55-.28-.55-.5l1.11-1.43v1.24Z"
        />
        <path
          class="cls-2"
          d="m71.77,41.2c.53-.53.75-.89.75-1.38,0-.82-.42-1.3-1.24-1.3-.86,0-1.24.57-1.24,1.37v.27h.69v-.27c0-.2,0-.68.55-.68.49,0,.54.36.54.61,0,.37-.33.7-.55.92-.62.62-1.25,1.09-1.25,2.04v.47h2.5v-1.29h-.66v.59h-1.11c0-.44.54-.86,1.03-1.35Z"
        />
        <path
          class="cls-2"
          d="m74.75,38.52c-.84,0-1.24.57-1.24,1.37v2.08c0,.8.41,1.37,1.24,1.37s1.24-.57,1.24-1.37v-2.08c0-.8-.41-1.37-1.24-1.37Zm-.56,1.37c0-.2.01-.7.56-.7.41,0,.55.28.55.5l-1.11,1.44v-1.24Zm1.12,2.08c0,.2-.01.7-.56.7-.41,0-.55-.28-.55-.5l1.11-1.43v1.24Z"
        />
        <polygon
          class="cls-2"
          points="15.15 35.26 10.64 23.81 7.7 23.81 7.7 43.21 10.64 43.21 10.64 31.76 15.15 43.21 18.08 43.21 18.08 23.81 15.15 23.81 15.15 35.26"
        />
        <path
          class="cls-2"
          d="m37.73,23.8c-3.5,0-5.19,2.37-5.19,5.73v7.97c0,2.62,1.02,5.73,5.19,5.73,3.5,0,5.19-2.4,5.19-5.73v-.87h-2.93v.87c0,.82-.03,2.82-2.26,2.82s-2.26-2-2.26-2.82v-7.97c0-.82.03-2.82,2.26-2.82s2.26,2,2.26,2.82v.85h2.93v-.85c0-2.65-.99-5.73-5.19-5.73Z"
        />
        <path
          class="cls-2"
          d="m18.08,16.4V7.91c0-2.91-1.66-5.47-5.64-5.47h-4.77v19.41h4.77c3.41,0,5.64-1.97,5.64-5.44Zm-7.53-11.11h1.95c2.37,0,2.68,1.89,2.68,2.71v8.32c0,.79-.31,2.71-2.68,2.71h-1.95V5.29Z"
        />
        <path
          class="cls-2"
          d="m48,21.85v-8.24h2c4.09,0,5.78-2.17,5.78-5.42s-1.69-5.75-5.78-5.75h-4.94v8.24h-9.42v-5.3h7.33v-2.93h-10.44v8.24h-9.31v-5.3h7.26v-2.93h-10.36v14.51h3.1v-3.34h9.31v8.24h10.44v-2.93h-7.33v-5.3h9.42v8.24h2.93Zm0-16.59h2c2.54,0,2.82,2.12,2.82,2.93s-.39,2.48-2.82,2.48h-2v-5.42Z"
        />
        <polygon
          class="cls-2"
          points="20.15 21.85 23.79 21.85 23.79 40.28 20.15 40.28 20.15 43.22 30.53 43.22 30.53 40.28 26.89 40.28 26.89 21.85 30.53 21.85 30.53 18.91 20.15 18.91 20.15 21.85"
        />
      </g>
      <motion.line
        class="cls-1"
        x1="7.77"
        y1="47.16"
        x2="85.88"
        y2="47.16"
        animate={{ x1: 7.77, x2: 85.88 }}
        initial={{ x1: -7.77, x2: -85.88 }}
        transition={{
          duration: 2,
          repeat: Infinity,
          repeatDelay: 2,
        }}
      />
    </svg>
  );
};

export default YourSVGComponentTwo;
