import React from "react";

import Form from "react-bootstrap/Form";

function CensusAgeNationalitySelect({
  title,
  ageNationalityRef,
  arrayMap,
  setSelectedAgeNationality,
  disabled,
}) {
  return (
    <Form.Select
      aria-label={title}
      className="census-select"
      disabled={disabled == null ? false : disabled == true ? true : false}
      ref={ageNationalityRef}
      onChange={(e) => {
        setSelectedAgeNationality(e.target.value);
      }}
    >
      <option hidden>{title}</option>
      {arrayMap.map((region, index) => (
        <option key={index} className="background-color-options" value={region}>
          {region}
        </option>
      ))}
    </Form.Select>
  );
}

export default CensusAgeNationalitySelect;
