import React from "react";
import FrontPageUpper from "../components/Core/FrontPageUpper";
import FooterNavBar from "../components/NavBars/FooterNavBar";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Business_Activity from "../pdfs/Business_Activity.pdf";
import Demography from "../pdfs/Demography.pdf";
import Monument_Building from "../pdfs/Monument_Building.pdf";
import lit_review from "../pdfs/lit_review.pdf";

import One_ENG from "../pdfs/One_ENG.pdf";
import One_GR from "../pdfs/One_GR.pdf";
import One_TR from "../pdfs/One_TR.pdf";

import Two_ENG from "../pdfs/Two_ENG.pdf";
import Two_GR from "../pdfs/Two_GR.pdf";
import Two_TR from "../pdfs/Two_TR.pdf";

import Three_ENG from "../pdfs/Three_ENG.pdf";
import Three_GR from "../pdfs/Three_GR.pdf";
import Three_TR from "../pdfs/Three_TR.pdf";

import Four_ENG from "../pdfs/Four_ENG.pdf";
import Four_GR from "../pdfs/Four_GR.pdf";
import Four_TR from "../pdfs/Four_TR.pdf";

import Five_ENG from "../pdfs/Five_ENG.pdf";
import Five_GR from "../pdfs/Four_GR.pdf";
import Five_TR from "../pdfs/Four_TR.pdf";

import DeepNic_Censuses from "../pdfs/DeepNic_Censuses.pdf";
import DeepNic_Monument from "../pdfs/DeepNic_Monument.pdf";
import DeepNic_Active from "../pdfs/DeepNic_Active.pdf";

import { BsCloudDownload } from "react-icons/bs";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

function Resources() {
  return (
    <>
      <FrontPageUpper
        picture={"resources_image.jpg"}
        credits={""}
        secondCredit={""}
      />
      <Container fluid style={{ background: "#C7C9C9" }}>
        <Row md={12}>
          <Col md={1}></Col>
          <Col md={5}>
            <p className="about-us-about-text">Resources</p>
          </Col>
        </Row>
        <Row md={12}>
          <Col md={1}></Col>
          <Col md={5}>
            <Accordion
              allowMultipleExpanded={true}
              allowZeroExpanded={true}
              className="accordion"
              style={{ width: "95%" }}
            >
              <AccordionItem>
                <AccordionItemHeading className="accordion-heading">
                  <AccordionItemButton>Publications</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ul>
                    <li>
                      <b>DeepNic Literature Review</b>
                      <br></br>
                      <b>Authors:</b> Antigone Heraclidou, CYENS Centre of
                      Excellence & Esra Plümer-Bardak, Association for
                      Historical Dialogue and Research
                      <br></br>
                      <a
                        href={lit_review}
                        download="Lit_review"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsCloudDownload size={20} color="black" />
                      </a>
                    </li>
                    <br></br>
                    <li>
                      <b>
                        Business Activity in the urban centre of Nicosia,
                        1960-2020: An overview
                      </b>
                      <br></br>
                      <b>Authors:</b> Antigone Heraclidou, CYENS Centre of
                      Excellence & Esra Plümer-Bardak, Association for
                      Historical Dialogue and Research
                      <br></br>
                      <a
                        href={Business_Activity}
                        download="Business_Activity"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsCloudDownload size={20} color="black" />
                      </a>
                    </li>
                    <br></br>
                    <li>
                      <b>
                        Monument Building in the urban centre of Nicosia,
                        1960-2020: An overview
                      </b>
                      <br></br>
                      <b>Authors:</b> Antigone Heraclidou, CYENS Centre of
                      Excellence & Esra Plümer-Bardak, Association for
                      Historical Dialogue and Research
                      <br></br>
                      <a
                        href={Monument_Building}
                        download="Monument_Building"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsCloudDownload size={20} color="black" />
                      </a>
                    </li>
                    <br></br>
                    <li>
                      <b>
                        An Overview on the demography of the urban centre of
                        Nicosia: Challenges, methodology and remarks
                      </b>
                      <br></br>
                      <b>Authors:</b> Antigone Heraclidou, CYENS Centre of
                      Excellence & Esra Plümer-Bardak, Association for
                      Historical Dialogue and Research
                      <br></br>
                      <a
                        href={Demography}
                        download="Demography"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsCloudDownload size={20} color="black" />
                      </a>
                    </li>
                    <br></br>
                    <li>
                      <b>DeepNic-Censuses 2011_Infographic</b>
                      <br></br>
                      <a
                        href={DeepNic_Censuses}
                        download="DeepNic-Censuses 2011_Infographic"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsCloudDownload size={20} color="black" />
                      </a>
                    </li>
                    <br></br>
                    <li>
                      <b>DeepNic Monument Infographics Map</b>
                      <br></br>
                      <a
                        href={DeepNic_Monument}
                        download="DeepNic_ Monument_Infographics_Map"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsCloudDownload size={20} color="black" />
                      </a>
                    </li>
                    <br></br>
                    <li>
                      <b>
                        DeepNic-Active Establishments in the neighbourhoods of
                        Nicosia Municipality 2008-2020
                      </b>
                      <br></br>
                      <a
                        href={DeepNic_Active}
                        download="DeepNic-Active Establishments in the neighbourhoods of Nicosia Municipality 2008-2020"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsCloudDownload size={20} color="black" />
                      </a>
                    </li>
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading className="accordion-heading">
                  <AccordionItemButton>
                    Educational Material
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ul>
                    <br></br>
                    <h5 style={{ textDecoration: "underline" }}>
                      All Levels (ENG,GR,TR)
                    </h5>
                    <li>
                      <b>How to use the DeepNic map</b>
                      <br></br>
                      <a
                        href={One_ENG}
                        download="How to use the DeepNic map"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsCloudDownload size={20} color="black" />
                      </a>
                    </li>
                    <br></br>
                    <li>
                      <b>Πώς να χρησιμοποιήσετε το χάρτη DeepNic</b>
                      <br></br>
                      <a
                        href={One_GR}
                        download="Πώς να χρησιμοποιήσετε το χάρτη DeepNic"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsCloudDownload size={20} color="black" />
                      </a>
                    </li>
                    <br></br>
                    <li>
                      <b>DeepNic haritası nasıl kullanılır</b>
                      <br></br>
                      <a
                        href={One_TR}
                        download="DeepNic haritası nasıl kullanılır"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsCloudDownload size={20} color="black" />
                      </a>
                    </li>
                    <br></br>
                    <br></br>
                    <h5 style={{ textDecoration: "underline" }}>
                      Primary Level (ENG,GR,TR)
                    </h5>
                    <li>
                      <b>Cultural diversity and the landscape</b>
                      <br></br>
                      <a
                        href={Two_ENG}
                        download="Cultural diversity and the landscape"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsCloudDownload size={20} color="black" />
                      </a>
                    </li>
                    <br></br>
                    <li>
                      <b>Ας φτιάξουμε ένα χάρτη της Λευκωσίας</b>
                      <br></br>
                      <a
                        href={Two_GR}
                        download="Ας φτιάξουμε ένα χάρτη της Λευκωσίας"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsCloudDownload size={20} color="black" />
                      </a>
                    </li>
                    <br></br>
                    <li>
                      <b>Kültürel çeşitlilik ve görünüm</b>
                      <br></br>
                      <a
                        href={Two_TR}
                        download="Ας φτιάξουμε ένα χάρτη της Λευκωσίας"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsCloudDownload size={20} color="black" />
                      </a>
                    </li>
                    <br></br>
                    <br></br>
                    <h5 style={{ textDecoration: "underline" }}>
                      Lower Secondary Level (ENG,GR,TR)
                    </h5>
                    <li>
                      <b>
                        The decline and revitalization of the commercial centre
                        of Nicosia
                      </b>
                      <br></br>
                      <a
                        href={Three_ENG}
                        download="The decline and revitalization of the commercial centre of Nicosia"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsCloudDownload size={20} color="black" />
                      </a>
                    </li>
                    <br></br>
                    <li>
                      <b>
                        Η παρακμή και η αναζωογόνηση του εμπορικού κέντρου της
                        Λευκωσίας
                      </b>
                      <br></br>
                      <a
                        href={Three_GR}
                        download="Η παρακμή και η αναζωογόνηση του εμπορικού κέντρου της Λευκωσίας"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsCloudDownload size={20} color="black" />
                      </a>
                    </li>
                    <br></br>
                    <li>
                      <b>
                        Lefkoşa'nın ticaret merkezinin inişi ve yeniden
                        canlandırılması
                      </b>
                      <br></br>
                      <a
                        href={Three_TR}
                        download="Lefkoşa'nın ticaret merkezinin inişi ve yeniden canlandırılması"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsCloudDownload size={20} color="black" />
                      </a>
                    </li>
                    <br></br>
                    <br></br>
                    <h5 style={{ textDecoration: "underline" }}>
                      Upper Secondary Level (ENG,GR,TR)
                    </h5>
                    <li>
                      <b>Gender and the economy Part I</b>
                      <br></br>
                      <a
                        href={Four_ENG}
                        download="Gender and the economy Part I"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsCloudDownload size={20} color="black" />
                      </a>
                    </li>
                    <br></br>
                    <li>
                      <b>Φύλο και οικονομία Μέρος Ι</b>
                      <br></br>
                      <a
                        href={Four_GR}
                        download="Φύλο και οικονομία Μέρος Ι"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsCloudDownload size={20} color="black" />
                      </a>
                    </li>
                    <br></br>
                    <li>
                      <b>Toplumsal cinsiyet ve ekonomi 1</b>
                      <br></br>
                      <a
                        href={Four_TR}
                        download="Toplumsal cinsiyet ve ekonomi 1"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsCloudDownload size={20} color="black" />
                      </a>
                    </li>
                    <br></br>
                    <li>
                      <b>Gender and the economy Part II</b>
                      <br></br>
                      <a
                        href={Five_ENG}
                        download="Gender and the economy Part II"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsCloudDownload size={20} color="black" />
                      </a>
                    </li>
                    <br></br>
                    <li>
                      <b>Φύλο και οικονομία Μέρος ΙΙ</b>
                      <br></br>
                      <a
                        href={Five_GR}
                        download="Φύλο και οικονομία Μέρος ΙΙ"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsCloudDownload size={20} color="black" />
                      </a>
                    </li>
                    <br></br>
                    <li>
                      <b>Toplumsal cinsiyet ve ekonomi 2</b>
                      <br></br>
                      <a
                        href={Five_TR}
                        download="Toplumsal cinsiyet ve ekonomi 2"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsCloudDownload size={20} color="black" />
                      </a>
                    </li>
                    <br></br>
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </Col>
        </Row>
        <FooterNavBar />
      </Container>
    </>
  );
}

export default Resources;
