import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "react-bootstrap/Button";

import { useNavigate } from "react-router-dom";

function SelectionButtons() {
  const navigate = useNavigate();

  return (
    <Container className="selection-container" fluid>
      <Row>
        <div>
          <Button
            onClick={() => {
              navigate("/census");
            }}
            className="population-census-button"
          >
            Demography
          </Button>
          <Button
            onClick={() => {
              navigate("/business");
            }}
            className="business-activity-button"
          >
            Business Activity
          </Button>
          <Button
            onClick={() => {
              navigate("/monuments");
            }}
            className="monuments-button"
          >
            Monument Building
          </Button>
        </div>
      </Row>
    </Container>
  );
}

export default SelectionButtons;
